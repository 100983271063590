import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {

  @Input() time: any;
  @Input() timeLeft: any;
  @Input() timeout: any;
  @Input() timeoutLeft: any;

  value: number = 100;
  newTime: any;
  progressTimeInterval: any;

  constructor() {
  }

  ngOnInit() {
    this.progressTime();
  }

  /**
   * Barra de progreso de tiempo del paso
   *
   */
  progressTime() {

    // Recuperar tiempo que le queda al paso
    if (this.timeLeft && this.timeout === 0) {

      this.progressTimeInterval = setInterval(() => {

        if (this.value > 0) {

          this.timeLeft--;

          this.value = ((this.timeLeft * 100) / this.time);

        } else {
          clearInterval(this.progressTimeInterval);
        }

      }, 1000);


      // Posponer tiempo
    } else if (this.timeout > 0) {

      this.progressTimeInterval = setInterval(() => {

        if (this.value > 0) {

          this.timeoutLeft--;

          this.value = ((this.timeoutLeft * 100) / this.timeout);

        } else {
          clearInterval(this.progressTimeInterval);
        }

      }, 1000);


      // Descontar tiempo inicial
    } else {
      this.newTime = this.time;

      this.progressTimeInterval = setInterval(() => {

        if (this.value > 0) {

          this.newTime--;

          this.value = ((this.newTime * 100) / this.time);

        } else {
          clearInterval(this.progressTimeInterval);
        }

      }, 1000);
    }

  }

}
