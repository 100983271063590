import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-product-initial',
  templateUrl: './product-initial.component.html',
  styleUrls: ['./product-initial.component.scss'],
})
export class ProductInitialComponent {

  @Input() elaboration: any;
  @Input() scale: any;

  @Input() value: any;
  @Output() onEditQuantity = new EventEmitter<any>();

  editQuantity() {
    this.onEditQuantity.emit(this.value);
  }

}
