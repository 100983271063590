import {Injectable} from '@angular/core';
import {FiltersModelsService} from '../Models/filters-models.service';
import {UserSessionService} from '../Models/user-session.service';
import {FixDevicesModelService} from '../Models/fix-devices-model.service';
import {DevicesApiService} from '../Api/devices-api.service';
import {EstablishmentsApiService} from '../Api/establishments-api.service';
import {FamiliesApiService} from '../Api/families-api.service';
import {LocationsApiService} from '../Api/locations-api.service';
import {NotificationsApiService} from '../Api/notifications-api.service';
import {ProductsApiService} from '../Api/products-api.service';
import {RecipesApiService} from '../Api/recipes-api.service';
import {StocksApiService} from '../Api/stocks-api.service';
import {TraceabilityApiService} from '../Api/traceability-api.service';
import {TurnsApiService} from '../Api/turns-api.service';
import {ProductionsApiService} from '../Api/productions-api.service';
import {DepartmentsApiService} from '../Api/departments-api.service';
import {WastageApiService} from '../Api/wastage-api.service';

@Injectable({
  providedIn: 'root'
})
export class DataManagerService {

  constructor(
    protected deviceService: DevicesApiService,
    protected establishmentService: EstablishmentsApiService,
    protected familyService: FamiliesApiService,
    protected filterModel: FiltersModelsService,
    protected productionService: ProductionsApiService,
    protected locationService: LocationsApiService,
    protected notificationService: NotificationsApiService,
    protected productService: ProductsApiService,
    protected recipeService: RecipesApiService,
    protected stockService: StocksApiService,
    protected traceabilityService: TraceabilityApiService,
    protected turnService: TurnsApiService,
    protected userSession: UserSessionService,
    protected fixDevicesModel: FixDevicesModelService,
    protected departmentsService: DepartmentsApiService,
    protected wastageService: WastageApiService,
  ) {
  }

  getData(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const promiseLocations = this.locationService.getAllLocationsApi();
      const promiseEstablishment = this.establishmentService.getEstablishmentsApi();
      const promiseDevices = this.deviceService.getDevicesApi();
      const promiseFamily = this.familyService.getFamiliesApi();
      const promiseDepartments = this.departmentsService.getDepartmentsApi();
      const promiseWastageTypes = this.wastageService.getWastageTypesApi();

      const promises = [
        promiseLocations,
        promiseEstablishment,
        promiseDevices,
        promiseFamily,
        promiseDepartments,
        promiseWastageTypes,
      ];

      Promise.all(promises)
        .then(() => resolve(true))
        .catch(error => reject(error));
    });
  }

  deleteData() {
    this.deviceService.model.delete();
    this.establishmentService.model.delete();
    this.departmentsService.model.delete();
    this.familyService.model.delete();
    this.filterModel.delete();
    this.productionService.modelProductionsProducts.delete();
    this.productionService.modelLastProductions.delete();
    this.locationService.model.delete();
    this.notificationService.model.delete();
    this.productService.model.delete();
    this.recipeService.model.delete();
    this.stockService.model.delete();
    this.traceabilityService.model.delete();
    this.turnService.model.delete();
    this.fixDevicesModel.delete();
    this.userSession.delete();
  }
}
