import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-notifications-items',
  templateUrl: './notifications-items.component.html',
  styleUrls: ['./notifications-items.component.scss'],
})
export class NotificationsItemsComponent implements OnInit {

  @Input() notification: any;

  @Output() onClickEndStep = new EventEmitter<any>();
  @Output() onClickTimeout = new EventEmitter<any>();
  @Output() onClickGoRecipe = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  endStep(e) {
    this.onClickEndStep.emit(this.notification);
  }

  timeoutNotification(e) {
    this.onClickTimeout.emit(this.notification);
  }

  goRecipe(e) {
    this.onClickGoRecipe.emit(this.notification);
  }

}
