import {Component, Injector, OnInit} from '@angular/core';
import {PageController} from '../../Core/page-controller/page-controller';
import {FiltersDto, FiltersModelsService} from '../../../Services/Models/filters-models.service';

@Component({
  selector: 'app-set-multisession',
  templateUrl: './set-multisession.page.html',
  styleUrls: ['./set-multisession.page.scss'],
})
export class SetMultisessionPage extends PageController implements OnInit {

  filters: FiltersDto;

  constructor(
    protected injector: Injector,
    protected filtersModel: FiltersModelsService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getFilters();
  }


  /**
   * Activar multisesión
   *
   */
  activateMultisession() {
    this.eventCtrl.publish({
      name: 'multisesion:active',
      data: this.filters
    });

    this.setFilters();
  }


  /**
   * Obtener filtros
   *
   */
  async getFilters() {
    this.filters = await this.filtersModel.list();
  }


  /**
   * Guardar filtros
   *
   */
  setFilters() {
    this.filtersModel.save(this.filters);
  }

}
