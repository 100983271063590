declare var $: any;

export class CustomString {
  public static Empty = '';

  public static capitalize(str): string {
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  }

  public static unCapitalize(str): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  public static booleanToString(value: boolean): string {
    return value ? 'Si' : 'No';
  }

  public static IsNullOrWhiteSpace(value: string): boolean {
    try {
      if (value == null || value === 'undefined') {
        return true;
      }

      return value.toString().replace(/\s/g, '').length < 1;
    } catch (e) {
      return false;
    }
  }

  public static replaceComa(value): number {
    let result;

    if (value) {
      result = parseFloat(value.toString().replace(',', '.'));

    } else {
      result = 0;
    }

    return result;
  }

  public static FormatCamelCase(value: string): string {
    const result = value.replace(/([A-Z]+)/g, ' $1');
    return result;
  }

  public static SplitComaToBr(value: string): string {
    const result = value.replace(/(,+)/g, '<br/>');
    return result;
  }

  public static cleanCharacter(value: string, char: string): string {
    if (value == null) {
      return value;
    }
    if (value.indexOf(char) === -1) {
      return value;
    } else {
      value = value.replace(char, '');
      return CustomString.cleanCharacter(value, char);
    }
  }

  public static Join(delimiter, ...args): string {
    try {
      if ($.isArray(args[0]) || args[0] === typeof Array) {
        let tempString = CustomString.Empty;
        const count = 0;

        for (let i = 0; i < args[0].length; i++) {
          const current = args[0][i];
          if (i < args[0].length - 1) {
            tempString += current + delimiter;
          } else {
            tempString += current;
          }
        }

        return tempString;
      } else if (typeof args[0] === 'object') {
        let tempString = CustomString.Empty;
        let count = 0;
        $(args[0]).each(function () {
          if (count < args[0].length - 1) {
            tempString += $(this).text() + delimiter;
          } else {
            tempString += $(this).text();
          }
          count++;
        });

        return tempString;
      }

      return CustomString.join(delimiter, args);
    } catch (e) {
      console.log(e);
      return CustomString.Empty;
    }
  }

  public static countChar(value, char): number {
    return (value.match(new RegExp(char, 'g')) || []).length;
  }

  public static Format(format, ...args): string {
    console.log(format, args);
    try {
      return format.toString().replace(/{(\d+(:\w*)?)}/g, (match, i) => {
        console.log(match, i);
        const s = match.split(':');
        if (s.length > 1) {
          i = i[0];
          match = s[1].replace('}', '');
        }

        const arg = CustomString.parsePattern(match, args[i]);
        return typeof arg !== 'undefined' && arg != null
          ? arg
          : CustomString.Empty;
      });
    } catch (e) {
      console.log(e);
      return CustomString.Empty;
    }
  }

  public static compareValues(value1: string, value2: string): number {
    return value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
  }

  private static parsePattern(match, arg): string {
    if (arg == null || arg === undefined) {
      return arg;
    }

    switch (match) {
      case 'L':
        arg = arg.toLowerCase();
        break;
      case 'U':
        arg = arg.toUpperCase();
        break;
      case 'd':
        {
          const splitted = arg.split('-');
          if (splitted.length <= 1) {
            return arg;
          }

          let day = splitted[splitted.length - 1];
          const month = splitted[splitted.length - 2];
          const year = splitted[splitted.length - 3];
          day = day.split('T')[0];
          day = day.split(' ')[0];

          arg = day + '.' + month + '.' + year;
        }
        break;
      case 's':
        {
          const splitted = arg.replace(',', '').split('.');
          if (splitted.length <= 1) {
            return arg;
          }

          let time = splitted[splitted.length - 1].split(' ');
          if (time.length > 1) {
            time = time[time.length - 1];
          }

          const year = splitted[splitted.length - 1].split(' ')[0];
          const month = splitted[splitted.length - 2];
          const day = splitted[splitted.length - 3];

          arg = year + '-' + month + '-' + day;
          if (time.length > 1) {
            arg += 'T' + time;
          } else {
            arg += 'T' + '00:00:00';
          }
        }
        break;

      case 'n':
        if (isNaN(parseInt(arg, 10)) || arg.length <= 3) {
          break;
        }

        arg = arg.toString();
        const mod = arg.length % 3;
        let output = mod > 0 ? arg.substring(0, mod) : CustomString.Empty;
        for (let i = 0; i < Math.floor(arg.length / 3); i++) {
          if (mod === 0 && i === 0) {
            output += arg.substring(mod + 3 * i, mod + 3 * i + 3);
          } else {
            output += '.' + arg.substring(mod + 3 * i, mod + 3 * i + 3);
          }
        }
        arg = output;
        break;
      default:
        break;
    }

    return arg;
  }

  private static join(delimiter, args): string {
    let temp = CustomString.Empty;
    for (let i = 0; i < args.length; i++) {
      if (
        CustomString.IsNullOrWhiteSpace(args[i]) ||
        (typeof args[i] !== 'number' && typeof args[i] !== 'string')
      ) {
        continue;
      }

      const arg = '' + args[i];
      temp += arg;
      for (let i2 = i + 1; i2 < args.length; i2++) {
        if (CustomString.IsNullOrWhiteSpace(args[i2])) {
          continue;
        }

        temp += delimiter;
        i = i2 - 1;
        break;
      }
    }
    return temp;
  }
}

export class StringBuilder {
  public Values = [];

  constructor(value: string = CustomString.Empty) {
    this.Values = new Array(value);
  }

  public ToString() {
    return this.Values.join('');
  }
  public Append(value: string) {
    this.Values.push(value);
  }
  public AppendFormat(value: string, ...args) {
    this.Values.push(CustomString.Format(value, args));
  }
  public Clear() {
    this.Values = [];
  }
}
