import { Injectable } from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class UsersModelService extends ModelService {

  tableName = 'kds_users';
}

export interface UserDto extends BaseDto {
  id: number;
}
