import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-product-initial-production',
  templateUrl: './product-initial-production.component.html',
  styleUrls: ['./product-initial-production.component.scss'],
})
export class ProductInitialProductionComponent implements OnInit {

  @Input() elaborationProduction: any;

  constructor() {
  }

  ngOnInit() {
  }

}
