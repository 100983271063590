import {Injectable} from '@angular/core';
import {KeyboardVirtualPage} from '../../Pages/Controls/keyboard-virtual/keyboard-virtual.page';
import {ModalController, ModalOptions} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class KeyboardManagerService {

  public keyboardMode = 'default';
  private keyboardModal: HTMLIonModalElement;

  constructor(
    protected modalCtrl: ModalController
  ) {
  }

  /**
   * Abrir teclado
   *
   */
  async openKeyboard(focusElement) {

    if (!!this.keyboardModal) {
      await this.keyboardModal.dismiss();
    }

    const modalOptions: ModalOptions = {
      component: KeyboardVirtualPage,
      componentProps: {
        element: focusElement,
        keyboardMode: this.keyboardMode
      },
      backdropDismiss: true,
      initialBreakpoint: 0.39,
      breakpoints: [0, 0.39],
      backdropBreakpoint: 0.39,
      cssClass: 'keyboard-modal'
    };

    if (this.keyboardMode === 'numeric') {
      modalOptions.initialBreakpoint = 0.37;
      modalOptions.breakpoints = [0, 0.37];
      modalOptions.backdropBreakpoint = 0.37;
      modalOptions.cssClass = 'keyboard-modal keyboard-numeric';
    }

    this.keyboardModal = await this.modalCtrl.create(modalOptions);

    this.keyboardModal.onDidDismiss().then(() => {
      this.keyboardModal = null;
    });

    await this.keyboardModal.present();
  }

}
