import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appNumber]'
})

export class NumberDirective {

  constructor(private el: ElementRef) {
  }

  @HostListener('window:keyup')


  /**
   *  Comprobar si el valor es numérico
   *
   */
  isNumber() {

    // Acceso al elemento input nativo
    let nativeEl: HTMLInputElement = this.el.nativeElement.querySelector('input');

    if (nativeEl) {
      let value = parseInt(nativeEl.value, 10);

      if (isNaN(value)) {
        return nativeEl.value = '';
      } else {
        return nativeEl.value = value.toString();
      }
    }
  }
}
