import {Component, HostListener, Injector, OnInit} from '@angular/core';
import {ModalPageController} from '../../Core/modal-page-controller/modal-page-controller';
import {LineOrderDto, OrdersDto} from '../../../Services/Models/orders-model.service';
import {IonItemSliding, NavParams} from '@ionic/angular';
import {LoaderManagerService} from '../../../Services/Components/loader-manager.service';
import {OrdersApiService} from '../../../Services/Api/orders-api.service';
import {CustomString} from '../../../Utils/strings';
import {ProductsSupplierDto} from '../../../Services/Models/products-supplier-model.service';
import {TraceabilityPage} from '../../Ingredients/traceability/traceability.page';
import {CustomNumber} from '../../../Utils/numbers';
import {PrinterIntegrationService} from '../../../Services/Integrations/integrations-printer.service';
import {FixDevicesModelService} from '../../../Services/Models/fix-devices-model.service';
import {DevicesApiService} from '../../../Services/Api/devices-api.service';
import {FiltersDto, FiltersModelsService} from '../../../Services/Models/filters-models.service';

@Component({
  selector: 'app-set-product-order',
  templateUrl: './set-product-order.page.html',
  styleUrls: ['./set-product-order.page.scss'],
})
export class SetProductOrderPage extends ModalPageController implements OnInit {

  order: OrdersDto;
  product: LineOrderDto;
  productsSupplier: ProductsSupplierDto[];
  isEditing: boolean;
  scales: any[];
  printers: any[];
  filters: FiltersDto;

  searchValue: string;

  totalCantTraz: number;

  form: {
    cantidad_total: number;
    cantidad: number;
    cods_trazabilidad: { id: number; codigo: string; cantidad: number }[];
  };

  intervalFocus: number;
  activateQrReader = false;

  constructor(
    protected injector: Injector,
    protected navParams: NavParams,
    protected loadCtrl: LoaderManagerService,
    protected printerService: PrinterIntegrationService,
    protected devicesService: DevicesApiService,
    protected fixDevicesModel: FixDevicesModelService,
    protected filtersModel: FiltersModelsService,
    protected ordersApiService: OrdersApiService
  ) {
    super(injector);
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(value): void {

    if (!this.product || !this.activateQrReader) {
      return;
    }

    this.qrReaderService.getValueQrReader(value.key).then((data) => {
      this.addCodTrazabiliad(data);
    });
  }

  ngOnInit() {
    this.order = this.navParams.get('order');
    this.product = this.navParams.get('line');
    this.productsSupplier = this.navParams.get('productsSupplier');
    this.isEditing = this.navParams.get('isEditing');
    this.scales = this.navParams.get('scales');
    this.printers = this.navParams.get('printers');

    if (this.product) {
      this.initForm();
    }

    this.checkFocus();
    this.getFilters();
  }


  /**
   * Comprueba si hay algún foco activo en un ion-input de la vista, si no hay, establece el foco en el input del lector123456bb
   *
   */
  checkFocus() {
    this.intervalFocus = setInterval(() => {
      const elements = document.querySelectorAll('ion-input');
      const elementsArray = Array.from(elements);

      const hasFocus = elementsArray.some((element) => element.classList.contains('has-focus'));

      this.activateQrReader = !hasFocus;
    }, 1000);
  }


  /**
   * Inicializar formulario
   *
   */
  initForm() {
    this.form = {
      cantidad_total: this.product.cantidad,
      cantidad: this.product.formato_multiplo
        ? Number((this.product.cantidad / this.product.formato_multiplo).toFixed(2))
        : this.product.cantidad,
      cods_trazabilidad: []
    };

    this.product.cods_trazabilidad?.forEach((item) => {
      this.form.cods_trazabilidad.push(item);
    });

    this.setTotalTraz();
  }


  /**
   * Asignar a la variable el producto seleccionado
   *
   */
  selectProduct(product) {
    this.product = product;
    this.initForm();
  }


  /**
   * Establecer la cantidad con sus formatos
   *
   */
  setQuantity() {
    this.form.cantidad = CustomString.replaceComa(this.form.cantidad);

    if (this.form.cantidad || this.form.cantidad === 0) {

      if (this.product.formato_minimo && this.form.cantidad < this.product.formato_minimo) {
        this.form.cantidad = this.product.formato_minimo;

        this.toastCtrl.showWarningToast('Tiene que tener un mínimo de ' + this.product.formato_minimo);
      }

      if (this.product.formato_multiplo) {
        this.form.cantidad_total = Number((this.product.formato_multiplo * this.form.cantidad).toFixed(2));

      } else {
        this.form.cantidad_total = this.form.cantidad;
      }

    } else {
      this.form.cantidad = 0;
      this.form.cantidad_total = this.form.cantidad;

      this.toastCtrl.showWarningToast('La cantidad debe de ser un campo numérico!');
    }

  }


  /**
   * Abrir modal de los lotes de trazabilidad
   *
   */
  async openModalTraceability(cod) {

    if (cod.codigo) {
      return;
    }

    this.activateQrReader = false;
    clearInterval(this.intervalFocus);

    const modal = await this.modalCtrl.create({
      component: TraceabilityPage,
      componentProps: {
        idProduct: this.product.id_producto
      },
      backdropDismiss: false,
      cssClass: 'force-normal'
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        cod.codigo = data.data.codigo || data.data;
      }

      this.checkFocus();
    });

    await modal.present();
  }


  /**
   * Dividir la cantidad de las lineas de trazabilidad por la cantidad total
   *
   */
  splitTrazQuantity() {
    this.setTotalTraz();
    this.setFilters();
  }


  /**
   * Añadir línea código de trazabilidad
   *
   */
  addCodTrazabiliad(code?: string) {

    const newItem = {
      id: null,
      codigo: code || null,
      cantidad: null
    };

    if (this.form.cantidad > this.totalCantTraz && !this.filters?.fraccionar_cant_traz) {
      newItem.cantidad = Number((this.form.cantidad - this.totalCantTraz).toFixed(2));
    }

    this.form.cods_trazabilidad.push(newItem);

    this.setTotalTraz();
  }


  /**
   * Elimina la línea código de trazabilidad
   *
   */
  deleteCod(data, itemSld: IonItemSliding) {

    itemSld.close();

    const index = this.form.cods_trazabilidad.indexOf(data);
    this.form.cods_trazabilidad.splice(index, 1);
  }


  /**
   * Cambiar cantidad código de trazabilidad
   *
   */
  changeCantidadTraz(data) {

    if (data.cantidad) {
      data.cantidad = CustomNumber.splitComaToPunto(data.cantidad.toString());
    }

    this.setTotalTraz();
  }


  /**
   * Calcular el total de trazabilidad
   *
   */
  setTotalTraz() {

    if (this.form.cantidad < 0) {
      return;
    }

    this.totalCantTraz = 0;

    for (const item of this.form.cods_trazabilidad) {

      if (this.filters?.fraccionar_cant_traz) {
        item.cantidad = Number((this.form.cantidad / this.form.cods_trazabilidad.length).toFixed(2));
      }

      this.totalCantTraz += Number(item.cantidad?.toString()) || 0;
    }

    setTimeout(() => {
      if (this.form.cantidad < this.totalCantTraz) {
        this.toastCtrl.showWarningToast('La cantidad total de trazabilidad es mayor a la cantidad');

        this.form.cantidad = Number(this.totalCantTraz.toFixed(2));
        this.setQuantity();
      }
    }, 100);
  }


  /**
   * Guardar resultados
   *
   */
  async saveProduct() {

    await this.loadCtrl.show();

    this.product.cantidad = this.form.cantidad_total;
    this.product.cods_trazabilidad = this.form.cods_trazabilidad;

    let response;

    if (this.isEditing) {

      response = await this.ordersApiService.setQuantityProduct(this.product)
        .finally(() => {
          this.loadCtrl.hide();
        });

    } else {

      this.product.id_pedido = this.order?.id;

      response = await this.ordersApiService.addProductOrder(this.product)
        .finally(() => {
          this.loadCtrl.hide();
        });
    }

    if (response.data) {
      this.closeModal(response.data);
    }

    this.toastCtrl.showToastByStatusCode(response.data, response.message);

  }


  /**
   * Eliminar producto
   *
   */
  deleteProduct() {
    this.loadCtrl.show();

    this.ordersApiService.deleteProductOrderApi({id: this.product.id_linea}).then((response) => {

      if (response.data) {
        this.closeModal(response.data);
      }

      this.toastCtrl.showToastByStatusCode(response.data, response.message);

    }).finally(() => {
      this.loadCtrl.hide();
    });
  }


  /**
   * Confirmar impresora
   *
   */
  async confirmPrinter(item, itemSld: IonItemSliding) {

    await itemSld.close();

    const fixDevices = await this.fixDevicesModel.list();

    // Comprobar si hay una impresora fijada
    if (fixDevices?.printer) {
      this.printCod(item, fixDevices.printer.ip);

      // Comprobar si hay una impresoras en ese local
    } else if (this.printers.length > 0) {

      if (this.printers.length > 1) {
        const options = {
          header: 'Seleccionar impresora',
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel'
            },
            {
              text: 'OK',
              handler: (ip) => {
                this.printCod(item, ip);
              }
            }
          ],
          inputs: []
        };

        for (const imp of this.printers) {
          options.inputs.push({
            type: 'radio',
            label: imp.nombre,
            value: imp.ip,
            checked: false
          });
        }

        const alerts = await this.alertCtrl.create(options);
        await alerts.present();

      } else {
        this.printCod(item, this.printers[0].ip);
      }

    } else {
      this.toastCtrl.showWarningToast('Ups, parece que no tienes ninguna impresora configurada en este local');
    }
  }


  /**
   * Imprimir etiqueta trazabilidad
   *
   */
  printCod(item, ip: string) {

    const data = {
      codigo: item.codigo,
      nombre: this.product.nombre,
      subtitulo: this.order.proveedor,
      unidad: this.product.unidad,
      cantidad: item.cantidad,
      fecha: this.order.fecha.original
    };

    this.printerService.productoPedido(data, ip);
  }


  /**
   * Cerrar vista modal
   *
   */
  closeModal(data: any = null, role: string = '') {
    clearInterval(this.intervalFocus);
    this.modalCtrl.dismiss(data, role);
  }


  /**
   * Obtener los filtros
   *
   */
  private async getFilters() {
    this.filters = await this.filtersModel.list();

    if (!this.filters.fraccionar_cant_traz) {
      this.filters.fraccionar_cant_traz = false;
    }
  }


  /**
   * Guardar los filtros
   *
   */
  private setFilters() {
    this.filtersModel.save(this.filters);
  }

}
