import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class RecipesModelService extends ModelService {

  tableName = 'kds_recipes';
}

export interface RecipesDto extends BaseDto {
  coste: number;
  descripcion: string;
  foto: string;
  id: number;
  id_familia: number;
  imagenes: Array<ImagesDto>;
  ingredientes: Array<IngredientDto>;
  is_elaboration: boolean;

  nombre: string;
  pasos: Array<StepsDto>;

  resultados: Array<ResultsDto>;
  youtube?: string;
}

export interface IngredientDto extends BaseDto {
  cantidad: number;
  cantidad_anterior: number;
  cantidad_bascula: number;
  cantidad_o: number;
  coste: number;
  descripcion: string;
  equivalencia: number;
  foto: string;
  id: number;
  id_producto: number;
  id_unidad: number;
  id_unidad_o: number;
  nombre: string;
  orden: number;
  resultado_elaboracion: number;
  resultado_receta: number;
  unidad: string;
  unidad_o: string;
}

export interface StepsDto extends BaseDto {
  activo: boolean;
  descripcion: string;
  done: false;
  horas: number;
  id: number;
  id_procedimiento: number;
  imagen: string;
  minutos: number;
  orden: number;
  time: string;
  titulo: string;
}

export interface ResultsDto extends BaseDto {
  cantidad: number;
  cantidad_anterior: number;
  dias_utiles: number;
  foto: string;
  id: number;
  id_familia?: number;
  id_producto: number;
  nombre: string;
  unidad: string;
}

export interface ImagesDto extends BaseDto {
  foto: string;
  foto_thum: string;
  id: number;
  orden: number;
}
