import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {


  transform(items: any[], key: string, search: string): any[] {

    if (items) {

      if (key && search) {

        items = items.filter((item) => {

          if (item?.[key]) {
            return item[key].toLowerCase().includes((search).toLowerCase());
          }

        });

      }

      return items;
    }

  }

}

