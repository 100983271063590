import {Injectable} from '@angular/core';
import {ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class TraceabilityModelService extends ModelService {

  tableName = 'kds_traceability';
}
