import {Injectable} from '@angular/core';
import {BaseApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {UserSessionService} from '../Models/user-session.service';
import {WastageTypesModelService} from '../Models/wastage-types-model.service';

@Injectable({
  providedIn: 'root'
})
export class WastageApiService extends BaseApiService {

  constructor(
    public http: HttpClient,
    public userSession: UserSessionService,
    public modelTypes: WastageTypesModelService,
  ) {
    super(http, userSession);
  }

  public getWastageTypesApi(save: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('getWastageTypes', null, 'get').subscribe((response: any) => {

        if (save) {
          this.data = this.modelTypes.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public addWastageApi(params): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('setWastage', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

}
