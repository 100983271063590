import {OrderStatusEnum} from '../Enum/order-status.enum';

export const OrderStatusConst = [
  {
    id: OrderStatusEnum.nuevo,
    nombre: 'Nuevo'
  },
  {
    id: OrderStatusEnum.enProceso,
    nombre: 'En proceso'
  }
];

export const OrderStatusConstAll = [
  {
    id: OrderStatusEnum.pendiente,
    nombre: 'Pendiente'
  },
  {
    id: OrderStatusEnum.nuevo,
    nombre: 'Nuevo'
  },
  {
    id: OrderStatusEnum.enProceso,
    nombre: 'En proceso'
  },
  {
    id: OrderStatusEnum.parcialEntregado,
    nombre: 'Parcial. entregado'
  }
];
