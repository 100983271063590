import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'list'
})
export class ListPipe implements PipeTransform {

  key: any;

  transform(items: any[], idFam: number = null, idLocal: number = null, arrayPartida: Array<number> = null, ubicacion?: string) {

    if (items) {

      // Familias
      if (idFam) {
        if (idFam !== -1) {

          this.key = 'id_familia';

          items = items.filter(item => {
            return item[this.key] === idFam;
          });

        }

      }

      // Locales
      if (idLocal) {

        if (idLocal !== -1) {

          this.key = 'id_local';

          items = items.filter(item => {
            return item[this.key] === idLocal;
          });

        }

      }

      // Partidas
      if (arrayPartida && arrayPartida.length > 0) {

        items = items.filter((item) => {

          if (arrayPartida.includes(item.id_partida)) {
            return item;
          }

        });

      }

      if (ubicacion) {
        items = items.filter((item) => item.ubicacion === ubicacion);
      }

      return items;
    }

  }

}
