import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ModalPageController} from '../../Core/modal-page-controller/modal-page-controller';
import {ApiResponse} from '../../../Services/Api/api.service';
import {TraceabilityApiService} from '../../../Services/Api/traceability-api.service';

@Component({
  selector: 'app-traceability',
  templateUrl: './traceability.page.html',
  styleUrls: ['./traceability.page.scss'],
})
export class TraceabilityPage extends ModalPageController implements OnInit {

  @ViewChild('input', {static: false}) ionInput: { setFocus: () => void };

  idProduct: number;
  allLotsTraceability: any;

  traceability: any;
  manualTraceability: any;

  constructor(
    protected injector: Injector,
    protected traceabilityService: TraceabilityApiService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.idProduct = this.navParams.get('idProduct');

    this.getLotsTraceability();
  }


  ionViewDidEnter() {
    this.ionInput.setFocus();
    this.traceability = -1;
  }


  /**
   * Obtener los lotes del trazabilidad
   *
   */
  getLotsTraceability() {
    this.traceabilityService.getTraceabilityApi(this.idProduct).then((response: ApiResponse) => {
      this.allLotsTraceability = response.data;
    });
  }


  /**
   * Seleccionar lote de trazabilidad
   *
   */
  selectTraceability() {

    const codTraz = this.traceability === -1
      ? this.manualTraceability
      : this.allLotsTraceability.find((item) => item.id === this.traceability);

    this.dismiss(codTraz);
  }

}
