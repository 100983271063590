import {Component, Injector, OnInit} from '@angular/core';
import {UserDto} from '../../../Services/Models/users-model.service';
import {UsersApiService} from '../../../Services/Api/users-api.service';
import {UserSessionService} from '../../../Services/Models/user-session.service';
import {KeyboardPage} from '../../Controls/keyboard/keyboard.page';
import {AuthService} from '../../../Services/Api/auth.service';
import {ModalController} from '@ionic/angular';
import {LoaderManagerService} from '../../../Services/Components/loader-manager.service';
import {FiltersDto, FiltersModelsService} from '../../../Services/Models/filters-models.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.page.html',
  styleUrls: ['./users-list.page.scss'],
})
export class UsersListPage implements OnInit {

  filters: FiltersDto;

  userActive: UserDto;
  users: UserDto[];

  search: string;

  constructor(
    protected injector: Injector,
    protected modalCtrl: ModalController,
    protected loadCtrl: LoaderManagerService,
    protected userSession: UserSessionService,
    protected usersService: UsersApiService,
    protected filtersModel: FiltersModelsService,
    protected auth: AuthService
  ) {
    this.userActive = this.userSession.get();
  }

  ngOnInit() {
    this.getFilters();
  }


  /**
   * Obtener filtros
   *
   */
  async getFilters() {
    this.filters = await this.filtersModel.list();

    this.getUsers();
  }


  /**
   * Abrir teclado
   *
   */
  async selectUser(userSelected) {
    const modal = await this.modalCtrl.create({
      component: KeyboardPage,
      componentProps: {
        user: userSelected
      },
      cssClass: 'mobile'
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.userActive = this.auth.updateUserSession(data.data);

        this.closeModal(this.userActive);
      }
    });

    await modal.present();
  }


  /**
   * Obtener los usuarios
   *
   */
  getUsers() {
    this.loadCtrl.show();

    this.usersService.getUsersApi(this.filters).then((response) => {
      this.users = response.data;

    }).finally(() => {
      this.loadCtrl.hide();
    });
  }


  /**
   * Cerrar vista modal
   *
   */
  closeModal(data = null, role = '') {
    this.modalCtrl.dismiss(data, role);
  }

}
