import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class DevicesModelService extends ModelService {

  tableName = 'kds_devices';
}

export interface DevicesDto extends BaseDto {
  id: number;
  id_local: number;
  id_partida: number;
  id_tipo: number;
  ip: string;
  local: string;
  nombre: string;
  partida: string;
  tipo: string;
}

