import * as moment from 'moment';
import {Component, Injector, OnInit} from '@angular/core';
import {ModalPageController} from '../../Core/modal-page-controller/modal-page-controller';
import {PresentationTypeEnum} from '../../../Utils/Enum/presentation-type.enum';
import {ProductionsApiService} from '../../../Services/Api/productions-api.service';

@Component({
  selector: 'app-add-more-info-tag',
  templateUrl: './add-more-info-tag.page.html',
  styleUrls: ['./add-more-info-tag.page.scss'],
})
export class AddMoreInfoTagPage extends ModalPageController implements OnInit {

  form: any;
  data: any;
  onlyLot: boolean;

  dateLimits: any;

  presentationTime: PresentationTypeEnum = PresentationTypeEnum.Time;

  constructor(
    protected injector: Injector,
    protected productionsService: ProductionsApiService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.data = this.navParams.get('item');
    this.onlyLot = this.navParams.get('onlyLot');

    const hoy = moment();
    const max = moment().add(5, 'year');

    this.dateLimits = {
      cad_min: hoy.format('YYYY-MM-DD'),
      cad_max: max.format('YYYY-MM-DD'),
    };

    this.initForm();
  }

  initForm() {
    this.form = {
      id: this.data.id,
      lote: this.data.lote,
      cantidad: null,
      caducidad_primaria: null,
      caducidad_primaria_has_time: null,
      caducidad_primaria_time: null,
      is_congelacion: null,
      is_descongelacion: null,
      is_apertura: null,
      tiempo_descongelacion: 12,
      tiempo_apertura: 1,
      is_elaboracion: !!this.data.id_elaboracion,
      num_imp: this.data?.num_imp || 1
    };
  }


  setInfoProduction() {
    if (this.form.lote) {
      this.productionsService.setLoteProductionApi(this.form).then((response) => {
        if (response.data) {
          this.dismiss(this.form);

        } else {
          this.toastCtrl.showWarningToast(response.message);
        }

      });

    } else {
      this.dismiss(this.form);
    }
  }

}
