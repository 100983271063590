import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-item-empty',
  templateUrl: './item-empty.component.html',
  styleUrls: ['./item-empty.component.scss'],
})
export class ItemEmptyComponent implements OnInit {

  @Input() img: any;
  @Input() msg: string;

  constructor() {
  }

  ngOnInit() {
  }

}
