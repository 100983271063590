import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {UserDto} from '../../../Services/Models/users-model.service';
import {AuthService} from '../../../Services/Api/auth.service';
import {ToastManagerService} from '../../../Services/Components/toastManager.service';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.page.html',
  styleUrls: ['./keyboard.page.scss'],
})
export class KeyboardPage implements OnInit {

  userSelected: UserDto;

  pin = '';
  pinVer = '';

  constructor(
    protected modalCtrl: ModalController,
    protected toastCtrl: ToastManagerService,
    protected navParams: NavParams,
    protected auth: AuthService
  ) {
  }

  ngOnInit() {
    this.userSelected = this.navParams.get('user');
  }

  public pressNumber(num: number) {
    if (this.pin?.length <= 3) {
      this.pin += num;
      this.pinVer += '*';

      if (this.pin.length === 4) {
        let params = {
          id: this.userSelected.id,
          pin: this.pin,
          pin_ver: this.pinVer,
          login_from: 0,
        };

        this.auth.loginByPin(params).then((response) => {

          if (response.data) {
            this.closeModal(response.data);

          } else {
            this.toastCtrl.showErrorToast(response.message);
          }

        }).finally(() => {
          this.deleteNumber();
        });
      }
    }
  }

  public deleteNumber() {
    this.pin = '';
    this.pinVer = '';
  }

  public closeModal(data = null, role = '') {
    this.modalCtrl.dismiss(data, role);
  }

}
