import {Component, Input, Output, EventEmitter} from '@angular/core';
import {LineOrderDto} from '../../../Services/Models/orders-model.service';
import {OrderStatusEnum} from '../../../Utils/Enum/order-status.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-order-products',
  templateUrl: './order-products.component.html',
  styleUrls: ['./order-products.component.scss'],
})
export class OrderProductsComponent {

  @Input() user: any;
  @Input() item: LineOrderDto;
  @Input() statusOrder: number;

  @Output() onClickCheckbox: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickQuantity: EventEmitter<any> = new EventEmitter<any>();

  statusOrderEnum = OrderStatusEnum;

  setCheckbox() {
    this.item.usuario = this.item.check_preparacion ? this.user.full_name : null;
    this.item.updated = this.item.check_preparacion ? moment().format('DD/MM/YYYY HH:mm') : null;

    this.onClickCheckbox.emit();
  }
}
