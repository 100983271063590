import { Injectable } from '@angular/core';
import {ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class FixDevicesModelService extends ModelService {

  tableName = 'kds_fix_devices';
}
