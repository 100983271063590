import {Injectable} from '@angular/core';
import {BaseApiService} from './api.service';
import {UserSessionService} from '../Models/user-session.service';
import {HttpClient} from '@angular/common/http';
import {RecipesModelService} from '../Models/recipes-model.service';

@Injectable({
  providedIn: 'root'
})
export class RecipesApiService extends BaseApiService {

  constructor(
    public http: HttpClient,
    public userSession: UserSessionService,
    public model: RecipesModelService) {
    super(http, userSession);
  }

  public getRecipesAndElaborationsApi(idUbi = null, params: any = null, save: boolean = true): Promise<any> {

    return new Promise((resolve, reject) => {

      let endpoint = '';

      if (idUbi) {
        endpoint = 'recetario/' + idUbi;
      } else {
        endpoint = 'recetario';
      }

      return this.httpBaseCall(endpoint, params, 'get').subscribe((response: any) => {

        if (save) {
          this.data = this.model.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public consultProductRecipeApi(id: number, params = null): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('recipe/' + id, params, 'get').subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }



}
