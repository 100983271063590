import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-productions-items',
  templateUrl: './productions-items.component.html',
  styleUrls: ['./productions-items.component.scss'],
})
export class ProductionsItemsComponent implements OnInit {

  @Input() production: any;
  @Input() showLastProduction: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
