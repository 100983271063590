import {Injectable} from '@angular/core';
import {DevicesApiService} from '../Api/devices-api.service';
import {AlertController} from '@ionic/angular';
import {ToastManagerService} from '../Components/toastManager.service';
import {FixDevicesModelService} from '../Models/fix-devices-model.service';
import {DevicesDto} from '../Models/devices-model.service';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsScalesService {

  public taraWeight = 0;

  private forcePrefix = 'http://';

  constructor(
    protected alertCtrl: AlertController,
    protected toastCtrl: ToastManagerService,
    protected devicesService: DevicesApiService,
    protected fixDevicesModel: FixDevicesModelService
  ) {
  }


  /**
   * Obtener peso de la báscula
   *
   */
  public async getWeightFromScale(scales: DevicesDto[], unit: string, isTara: boolean): Promise<number> {

    return new Promise((resolve) => {

      if (isTara && this.taraWeight > 0) {
        this.taraWeight = 0;
        return;
      }

      this.selectScale(scales).then((ip) => {

        if (ip) {

          this.connectScale(ip, unit, isTara).then((data) => {
            resolve(data);
          });

        }

      });

    });

  }


  /**
   * Seleccionar báscula
   *
   */
  private async selectScale(scales: DevicesDto[]): Promise<string> {

    return new Promise((resolve) => {

      (async () => {

        const fixScale = await this.fixDevicesModel.list();

        if (fixScale && fixScale.scale) {
          resolve(fixScale.scale.ip);
          return;
        }

        if (scales.length === 0) {
          this.toastCtrl.showWarningToast('Parece que no tienes ninguna bascula configurada en este local');
          return;
        }

        if (scales.length > 1) {

          const options = {
            header: 'Seleccionar báscula',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel'
              },
              {
                text: 'Ok',
                handler: (data) => {
                  resolve(data);
                }
              }],
            inputs: []
          };

          for (const item of scales) {
            options.inputs.push({
              type: 'radio',
              label: item.nombre,
              value: item.ip,
              checked: false
            });
          }

          const alerts = await this.alertCtrl.create(options);
          return await alerts.present();
        }

        resolve(scales[0].ip);

      })();

    });

  }


  /**
   * Conectar con la báscula
   *
   */
  private async connectScale(ip: string, unit: string, isTara: boolean): Promise<number> {

    return new Promise((resolve) => {

      const url = this.forcePrefix + ip;
      const method = 'GET';
      const async = true;
      const request = new XMLHttpRequest();

      request.open(method, url, async);
      request.send('');
      request.timeout = 2000;

      request.onload = () => {
        const data = request.responseText; // Returned data, e.g., an HTML document.

        resolve(this.cleanData(data, unit, isTara));
      };

      request.onerror = ((err) => {
        this.toastCtrl.showWarningToast('Error de conexión con la báscula');
        console.log(err);
      });

      request.ontimeout = ((e) => {
        this.toastCtrl.showWarningToast('Se ha perdido la conexión con la báscula');
      });

    });

  }


  /**
   * Limpiar dato recibido
   *
   */
  private cleanData(data: any, unit: string, isTara: boolean): number {
    const regexData = /<w1>(.*?)<\/w1>/;
    const match = data.match(regexData);

    if (!match) {
      return 0;
    }

    const output = match[1].trim();

    const regexOutput = /(\d+\.\d+)\s*(\w+)/;
    const matchOutput = output.match(regexOutput);

    const weight = matchOutput[1];
    const unitScale = matchOutput[2].toLowerCase();
    const unitProduct = unit.toLowerCase();

    return this.calculateWeight(weight, unitScale, unitProduct, isTara);
  }


  /**
   * Calcular peso dependiendo de la unidad
   *
   */
  private calculateWeight(weight, unitScale, unitProduct: string, isTara: boolean): number {

    let result = +weight;

    if ('kg' === unitScale && 'gr' === unitProduct) {
      result = weight * 1000;
    }

    if ('gr' === unitScale && 'kg' === unitProduct) {
      result = weight / 1000;
    }

    if (isTara) {
      this.taraWeight = result;
      return;
    }

    if (this.taraWeight > 0) {
      result = result - this.taraWeight;
    }

    return parseFloat(result.toFixed(2));
  }


  /**
   * Obtener básculas
   *
   */
  private async getScales(idLocal: number, tipo = 1) {
    const aux = await this.devicesService.model.list();
    return aux.filter((dispositivo) => (!idLocal || dispositivo.id_local === idLocal) && dispositivo.id_tipo === tipo);
  }

}
