import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersModelsService extends ModelService {

  tableName = 'kds_filters';
}

export interface FiltersDto extends BaseDto {
  id_local: number;
  id_ubicacion: number;
  id_departamento: number;
  id_familia: number;
  id_familia_mise: number;
  id_partida: Array<number>;
  id_turno: number;
  produccion_activa: boolean;
  multisesion: MultisesionFilters;
  filtro_estado: number;
  ubicacion_prod: string;
  ordenar_prod: string;
  direccion_prod: string;
  fraccionar_cant_traz: boolean;
  teclado_kds: boolean;
}

export interface MultisesionFilters extends BaseDto {
  multisesion_active: boolean;
  auto_lock: boolean;
  logout_lock: boolean;
}
