import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appSelectAll]'
})
export class SelectAllDirective {

  constructor(private el: ElementRef) {
  }

  @HostListener('click')


  /**
   *  Seleccionar todo el input
   *
   */
  selectAll() {

    // acceso al elemento input nativo
    let nativeEl: HTMLInputElement = this.el.nativeElement.querySelector('input');

    if (nativeEl) {

      if (nativeEl.setSelectionRange) {

        // selecciona el texto desde inicio a fin
        return nativeEl.setSelectionRange(0, nativeEl.value.length);
      }

      nativeEl.select();
    }
  }


}
