import {Component, Injector, OnInit} from '@angular/core';
import {ModalPageController} from '../../Core/modal-page-controller/modal-page-controller';
import {ProductionsApiService} from '../../../Services/Api/productions-api.service';
import {PresentationTypeEnum} from '../../../Utils/Enum/presentation-type.enum';
import * as moment from 'moment';

@Component({
  selector: 'app-add-continued-info',
  templateUrl: './add-continued-info.page.html',
  styleUrls: ['./add-continued-info.page.scss'],
})
export class AddContinuedInfoPage extends ModalPageController implements OnInit {

  data: any;
  infoTag: any;
  form: any;

  presentationTypes = PresentationTypeEnum;

  constructor(
    protected override injector: Injector,
    protected productionsApiService: ProductionsApiService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.data = this.navParams.get('item');

    this.initForm();
    this.getInfoTag();
  }


  /**
   * Inicializar formulario
   *
   */
  initForm() {
    this.form = {
      referencia: this.data.referencia,
      fecha_produccion: null,
      lote: null,
      is_congelacion: false,
      fecha_congelacion: null,
      is_descongelacion: false,
      fecha_descongelacion: null,
      tiempo_descongelacion: null,
      num_imp: this.data?.num_imp || 1
    };
  }


  /**
   * Desactivar la opción de congelación limpia la fecha y se desactiva la opción de descongelación
   *
   */
  toggleFreezeMode() {
    if (!this.form.is_congelacion) {
      this.form.fecha_congelacion = null;

      this.form.is_descongelacion = false;
      this.form.fecha_descongelacion = null;
      this.form.tiempo_descongelacion = null;
    }
  }


  /**
   * Al desactivar la opción de descongelar limpia la fecha de descongelación y el tiempo de descongelación
   *
   */
  toggleDefrostMode() {
    if (!this.form.is_descongelacion) {
      this.form.fecha_descongelacion = null;
      this.form.tiempo_descongelacion = null;

    } else {
      this.form.tiempo_descongelacion = this.form.tiempo_descongelacion || 12;
    }
  }


  /**
   * Obtener información de la etiqueta
   *
   */
  getInfoTag() {
    this.loadCtrl.show();

    this.productionsApiService.getInfoTag({referencia: this.form.referencia}).then((response) => {

      this.infoTag = response.data;

      if (this.infoTag) {
        this.form.fecha_produccion = moment(this.infoTag.fecha_produccion).format('yyyy-MM-DDTHH:mm');
        this.form.lote = this.infoTag.lote;

        this.form.fecha_congelacion = this.infoTag.fecha_congelacion ? moment(this.infoTag.fecha_congelacion).format('yyyy-MM-DDTHH:mm') : null;
        this.form.is_congelacion = !!this.infoTag.fecha_congelacion;

        this.form.fecha_descongelacion = this.infoTag.fecha_descongelacion ? moment(this.infoTag.fecha_descongelacion).format('yyyy-MM-DDTHH:mm') : null;
        this.form.tiempo_descongelacion = this.infoTag.tiempo_descongelacion;
        this.form.is_descongelacion = !!this.infoTag.fecha_descongelacion;
      }

    }).finally(() => {
      this.loadCtrl.hide();
    });
  }


  /**
   * Comprobar formulario
   *
   */
  checkInfoProduction() {

    if (this.form.is_congelacion && !this.form.fecha_congelacion) {
      this.toastCtrl.showWarningToast('Introduce la fecha de congelación para poder continuar');
      return;
    }

    if (this.form.is_descongelacion && (!this.form.fecha_descongelacion || !this.form.tiempo_descongelacion)) {
      this.toastCtrl.showWarningToast('Introduce todos los campos de descongelación para poder continuar');
      return;
    }

    this.setInfoProduction();
  }


  /**
   * Guardar información de la etiqueta
   *
   */
  setInfoProduction() {
    this.loadCtrl.show();

    this.productionsApiService.setInfoTag(this.form).then((response) => {

      if (response.data) {

        response.data.num_imp = this.form.num_imp;

        this.dismiss(response.data);

      } else {

        this.toastCtrl.showErrorToast(response.message);
      }

    }).finally(() => {
      this.loadCtrl.hide();
    });
  }

}
