import {Pipe, PipeTransform} from '@angular/core';
import {orderBy} from 'lodash';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(items: any[], key: string, active: boolean = false, direction: string = 'desc'): any[] {

    if (items) {

      if (active) {

        if (key && direction) {
          items = orderBy(items, [key], [direction]);
        }

      }

      return items;
    }

  }

}

