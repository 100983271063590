import {Injectable} from '@angular/core';
import {ApiResponse, BaseApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {UserSessionService} from '../Models/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {

  constructor(
    public http: HttpClient,
    public userSession: UserSessionService
  ) {
    super(http, userSession);
  }

  public login(form: LoginRequest): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('login', form).subscribe((response: any) => {

        if (response.data) {
          this.user = this.userSession.set(response.data);
        }

        resolve(response);

      }, (err: any) => {

        reject(err);

      });
    });
  }

  public register(form): Promise<ApiResponse> {

    return new Promise((resolve, reject) => {

      return this.httpCall('register', form).subscribe({
        next: (response) => {

          if (response.data) {
            this.user = this.userSession.set(response.data);
          }

          resolve(response);

        },
        error: (err) => {
          reject(err);
        }
      });

    });

  }

  logout(user): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('logout', {user}).subscribe(
        (response: ApiResponse) => {
          this.deleteUserSession();
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public loginByPin(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('loginWithPin', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public refreshPermissions(): Promise<ApiResponse> {

    this.user = this.userSession.get();

    return new Promise((resolve, reject) => {
      return this.httpBaseCall('refreshPermissions', {id: this.user.id}).subscribe(
        (response: ApiResponse) => {
          if (response.data) {
            this.user.permisos = response.data;
            this.user = this.userSession.set(this.user);
          }

          resolve(response);
        },
        (err: ApiResponse) => {
          reject(err);
        }
      );
    });
  }

  public updateUserSession(data) {
    this.user = this.userSession.set(data);
    return this.user;
  }

  public deleteUserSession() {
    this.user = this.userSession.delete();
  }

  public userIsLogIn(): boolean {
    this.user = this.userSession.get();
    return this.user && 'id' in this.user && 'token' in this.user;
  }
}

export interface LoginRequest {
  email: string;
  password: string;
}
