import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {KeyboardManagerService} from '../../Services/Components/keyboard-manager.service';
import {IonInput} from '@ionic/angular';
import {FiltersDto, FiltersModelsService} from '../../Services/Models/filters-models.service';

@Directive({
  selector: '[yuKeyboard]'
})
export class KeyboardDirective {

  @Input() keyboardMode = 'default';
  @Input() forceKeyboard = false;

  constructor(
    protected elementRef: ElementRef<IonInput>,
    protected filtersModel: FiltersModelsService,
    protected keyboardManager: KeyboardManagerService
  ) {
  }

  @HostListener('focusin', ['$event'])
  async onFocusIn(event) {

    const filters: FiltersDto = await this.filtersModel.list();

    if (!filters?.teclado_kds && !this.forceKeyboard) {
      return;
    }

    event.preventDefault();

    this.keyboardManager.keyboardMode = this.keyboardMode;
    this.keyboardManager.openKeyboard(this.elementRef.nativeElement);
  }

}
