import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsSupplierModelService extends ModelService {

  tableName = 'kds_products_orders';
}

export interface ProductsSupplierDto extends BaseDto {
  id: number;
  id_: number;
  id_producto: null;
  id_pcp: null;
  id_articulo: number;
  foto: null;
  referencia: string;
  nombre: string;
  descripcion: string;
  ubicacion: string;
  cantidad: number;
  id_unidad: number;
  unidad: string;
  id_familia: number;
  familia: string;
  habitual: number;
  precio: number;
  precio_tarifa: number;
  precio_final: number;
  descuento: number;
  descripcion_compra: string;
  es_peso: number;
  formato: number;
  formato_compra: string;
  formato_minimo: number;
  formato_multiplo: number;
  formato_multiplo_label: string;
  formato_ver: string;
  id_iva: number;
  iva: number;
  activo: number;
  articulo: string;
  favorito: number;
  locked: boolean;
}
