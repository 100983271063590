import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import Pusher from 'pusher-js';

@Injectable({
  providedIn: 'root'
})

export class PusherEventsService {

  pusher: any;

  initPusher() {
    this.pusher = new Pusher(environment.appKeyPusher, {
      cluster: 'eu',
    });
  }

  observe(nameChannel, itemChannel) {
    if (!this.pusher) {
      this.initPusher();
    }

    return this.pusher.subscribe(nameChannel + itemChannel);
  }

  disconnect(nameChannel, itemChannel) {
    return this.pusher.unsubscribe(nameChannel + itemChannel);
  }

}
