import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class IonicEventsService {

  private fooSubject = new Subject<any>();
  subscriptions: any[] = [];

  publish(data: EventDto) {
    this.fooSubject.next(data);
  }

  observe(name: string): Observable<EventDto> {

    if (!this.subscriptions[name]) {
      this.subscriptions[name] = this.fooSubject.pipe(filter(ev => ev.name === name));
      return this.subscriptions[name];
    }

    return null;
  }

  unsubscribe(name: string) {
    if (this.subscriptions[name]) {
      this.subscriptions[name].unsubscribe();
      this.subscriptions[name] = null;
    }
  }
}

export interface EventDto {
  name: string;
  data?: any;
}
