import {Injectable} from '@angular/core';
import {BaseApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {UserSessionService} from '../Models/user-session.service';
import {TraceabilityModelService} from '../Models/traceability-model.service';

@Injectable({
  providedIn: 'root'
})
export class TraceabilityApiService extends BaseApiService {

  constructor(
    public http: HttpClient,
    public userSession: UserSessionService,
    public model: TraceabilityModelService
  ) {
    super(http, userSession);
  }

  public getTraceabilityApi(id, params: any = null, save: boolean = true): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('traceability/codes/' + id, params, 'get').subscribe((response: any) => {

        if (save) {
          this.data = this.model.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }


  public getLinesTraceabilityFromProductApi(idProduction, idProduct, params: any = null, save: boolean = true): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('traceability/lines/' + idProduction + '/' + idProduct, params, 'get').subscribe((response: any) => {

        if (save) {
          this.data = this.model.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public updateTraceabilityLinesRecipeApi(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('traceability/lines', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public deleteLineApi(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.deleteHttpCall('traceability/line/' + id).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }


  public getLinesTraceabilityFromProductElaborationApi(idProduction, idProduct, params: any = null, save: boolean = true): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('traceability/elaboration/lines/' + idProduction + '/' + idProduct, params,
        'get').subscribe((response: any) => {

        if (save) {
          this.data = this.model.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public updateTraceabilityLinesElaborationApi(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postHttpCall('traceability/elaboration/lines', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public deleteLineElaborationApi(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.deleteHttpCall('traceability/elaboration/line/' + id).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));
    });
  }
}
