import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicStorageModule} from '@ionic/storage-angular';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {InterceptorProvider} from './Providers/Interceptor/interceptor';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ComponentsModule} from './Components/components.module';

import es from '@angular/common/locales/es';
import {registerLocaleData} from '@angular/common';

registerLocaleData(es);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot({mode: 'ios'}),
    HttpClientModule,
    AppRoutingModule,
    ComponentsModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'es'},
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorProvider, multi: true}
    ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
