import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';
import {FamiliesDto} from './families-model.service';

@Injectable({
  providedIn: 'root'
})
export class StocksModelService extends ModelService {

  tableName = 'kds_stocks';
}

export interface MiseEnPlaceDto extends BaseDto {
  familias: Array<FamiliesDto>;
  partidas: Array<ShipmentsDto>;
  stock: Array<Stock>;
}

export interface ShipmentsDto extends BaseDto {
  id: number;
  nombre: string;
}

export interface Stock extends BaseDto {
  cantidad: number;
  elaboracion: number;
  familia: string;
  foto: string;
  has_activa: number;
  hora: string;
  hora_fin: string;
  hora_inicio: string;
  id: number;
  id_almacen_ubicacion: number;
  id_balanza: string;
  id_familia: number;
  id_familia_pricipal: number;
  id_local: number;
  id_partida: number;
  id_produccion: number;
  id_producto: number;
  id_ubicacion: number;
  id_ubicacion_descuente: number;
  inventariar: boolean;
  inventario_antes_producir: number;
  last_produccion: Array<LastProductionMise>;
  nombre: string;
  orden: number;
  partida: string;
  tipo: number;
  receta: number;
  ubicacion: string;
  unidad: string;
  unidad_tipo: number;
}

export interface LastProductionMise extends BaseDto {
  caducado: number;
  cantidad_entrada: number;
  cantidad_salida: number;
  dias_utiles: number;
  dias_utiles_da: number;
  fecha_cad: string;
  fecha_cad_u: string;
  fecha_fin: string;
  fecha_fin_u: string;
  fecha_inicio: string;
  fecha_inicio_u: string;
  id: number;
  id_cliente: number;
  id_elaboracion: number;
  id_local: number;
  id_ubicacion: number;
  id_usuario: number;
  local: string;
  nombre: string;
  observaciones: string;
  referencia: string;
  ubicacion: string;
  usuario: string;
}

