import {Injectable} from '@angular/core';
import {BaseApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {UserSessionService} from '../Models/user-session.service';
import {StocksModelService} from '../Models/stocks-model.service';

@Injectable({
  providedIn: 'root'
})
export class StocksApiService extends BaseApiService {

  constructor(
    public http: HttpClient,
    public userSession: UserSessionService,
    public model: StocksModelService
  ) {
    super(http, userSession);
  }

  public getMiseEnPlaceApi(params, save: boolean = true): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('products/list', params, 'post').subscribe((response: any) => {

        if (save) {
          this.data = this.model.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public getMiseEnPlaceFamiliesApi(params): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('products/families', params, 'post').subscribe((response: any) => {


        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public getMiseEnPlaceShipmentsApi(params): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('products/shipments', params, 'post').subscribe((response: any) => {

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public setInventory(params): Promise<any> {

    return new Promise((resolve, reject) => {

      this.httpBaseCall('setInventory', params, 'post').subscribe((response: any) => {
        resolve(response);

      }, (err: any) => reject(err));
    });

  }

}
