import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-product-final',
  templateUrl: './product-final.component.html',
  styleUrls: ['./product-final.component.scss'],
})
export class ProductFinalComponent {

  @Input() elaboration: any;
  @Input() scale: any;

  @Input() value: any;
  @Output() onEditQuantity = new EventEmitter<any>();

  editQuantity() {
    this.onEditQuantity.emit(this.value);
  }

}
