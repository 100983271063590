import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {Haptics, ImpactStyle} from '@capacitor/haptics';

@Component({
  selector: 'app-counter-input',
  templateUrl: './counter-input.component.html',
  styleUrls: ['./counter-input.component.scss'],
})
export class CounterInputComponent implements OnInit {

  @Output() valueChange = new EventEmitter<number>();
  @Output() radioChange = new EventEmitter<string>();

  @Input() value: number = 0;
  @Input() radio: string;
  @Input() addText: string = 'Personalizado';

  ngOnInit() {
    this.value = 0;
  }


  hapticsImpactHeavy = async () => {
    await Haptics.impact({style: ImpactStyle.Heavy});
  };


  setQuantity(cant): void {
    this.hapticsImpactHeavy();

    if (this.value + cant >= 0 || this.value + cant <= 0) {
      this.value = this.value + cant;
    }

    this.setRadio();

    this.valueChange.emit(this.value);
  }


  setRadio() {
    this.radio = '-1';

    this.radioChange.emit(this.radio);
  }
}
