import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-steps-recipe',
  templateUrl: './steps-recipe.component.html',
  styleUrls: ['./steps-recipe.component.scss'],
})
export class StepsRecipeComponent implements OnInit {

  @Input() step: any;
  @Input() i: number;
  @Input() menu: string;

  constructor() {
  }

  ngOnInit() {
  }

}
