import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'searchProduction'
})
export class SearchProductionPipe implements PipeTransform {

  transform(items: any[], search: string): any[] {

    if (items) {

      if (search) {

        items = items.filter((item) => {

          if (item.nombre && item.referencia) {
            return item.nombre.toLowerCase().includes((search).toLowerCase()) ||
              item.referencia.toLowerCase().includes((search).toLowerCase());
          }

        });

      }

      return items;
    }

  }

}
