import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.scss'],
})
export class ItemsListComponent implements OnInit {

  @Input() item: any;
  @Input() description: string;
  @Input() reorder: boolean = false;
  @Input() menu: string;
  @Input() itemSelected: any;
  @Input() iconTitle: string = '';
  @Input() iconColor: string = 'primary';

  @Output() clickItem = new EventEmitter<any>();

  fecha_caducidad: any;
  caducado: boolean;

  ngOnInit() {
    if (this.menu === 'miseEnPlace') {
      this.checkExpirationDate();
    }
  }


  /**
   * Comprobar la fecha de caducidad si un producto es una receta y una elaboración - Mise en place
   *
   */
  checkExpirationDate() {
    if (this.item.fecha_cad && this.item.fecha_cad_elab) {

      let fecha1 = new Date(this.item.fecha_cad_o);
      let fecha2 = new Date(this.item.fecha_cad_elab_o);

      if (fecha1 > fecha2) {
        this.fecha_caducidad = this.item.fecha_cad;
        this.caducado = this.item.caducado;

      } else {
        this.fecha_caducidad = this.item.fecha_cad_elab;
        this.caducado = this.item.caducado_elab;
      }

    } else if (this.item.fecha_cad) {
      this.fecha_caducidad = this.item.fecha_cad;
      this.caducado = this.item.caducado;

    } else {
      this.fecha_caducidad = this.item.fecha_cad_elab;
      this.caducado = this.item.caducado_elab;
    }
  }

}
