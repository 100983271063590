import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsModelService extends ModelService {

  tableName = 'kds_products';
}

export interface ProductsDto extends BaseDto {
  activo: number;
  compra: number;
  coste: number;
  coste_ok: number;
  coste_unidad_master: number;

  descripcion: string;

  foto: string;
  id: number;
  id_cliente: number;
  id_familia: number;
  id_familia_ventas: number;
  id_generico: number;
  id_iva: number;
  id_producto_externo: number;
  id_tpv: number;
  id_unidad: number;
  id_vajilla: number;
  id_zona_saona: number;
  imagen_url: string;
  iva_aplicable: number;

  nombre: string;
  precio_mejor: number;
  precio_referencia: number;
  precio_venta: number;
  resultado_elaboracion: number;
  resultado_receta: number;
  unidad: Array<UnitDto>;

  video: string;
  video_youtube: string;
}

export interface UnitDto extends BaseDto {
  activo: number;

  equivalencia_master: number;
  full_type: string;
  id: number;
  id_balanza: string;
  master: string;
  master_completo: string;
  nombre: string;
  nuevos: number;
  simbolo: string;
  tipo: number;

  url?: string;
  youtube?: string;
}
