import {AfterViewInit, Component, Input, ViewEncapsulation} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {KeyboardOptions} from 'simple-keyboard/build/interfaces';
import Keyboard from 'simple-keyboard';

@Component({
  selector: 'app-keyboard-virtual',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './keyboard-virtual.page.html',
  styleUrls: ['./keyboard-virtual.page.scss'],
})
export class KeyboardVirtualPage implements AfterViewInit {

  @Input() element: any;
  @Input() keyboardMode = 'default';

  private keyboard: Keyboard;
  private keyboardOptions: KeyboardOptions = {
    layout: {
      default: [
        '\\ 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{tab} q w e r t y u i o p [ ] \\',
        '{lock} a s d f g h j k l ; \ {enter}',
        '{shift} z x c v b n m , . / {shift}',
        '{space}'
      ],
      shift: [
        '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
        '{tab} Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : " {enter}',
        '{shift} Z X C V B N M &lt; &gt; ? {shift}',
        '{space}'
      ],
      numeric: [
        '1 2 3 -',
        '4 5 6 {space}',
        '7 8 9 {bksp}',
        ', 0 . {enter}'
      ]
    },
    display: {
      '{bksp}': '⌫',
      '{enter}': '↵',
      '{tab}': '⇥',
      '{lock}': '⇪',
      '{shift}': '⇧',
      '{space}': '␣',
    },
    onChange: input => this.onChange(input),
    onKeyPress: button => this.onKeyPress(button)
  };

  constructor(
    protected modalCtrl: ModalController
  ) {
  }

  ngAfterViewInit() {

    if (this.keyboardMode === 'numeric') {
      this.keyboardOptions.layoutName = 'numeric';
    }

    this.keyboard = new Keyboard(this.keyboardOptions);
  }

  onChange = (input: string) => {
    this.element.value = input;
  };

  onKeyPress = (button: string) => {
    if (button === '{shift}' || button === '{lock}') {
      this.handleShift();
    }

    if ('{enter}' === button) {
      this.closeKeyboard();
    }
  };

  handleShift = () => {
    const currentLayout = this.keyboard.options.layoutName;

    this.keyboard.setOptions({
      layoutName: currentLayout === 'default' ? 'shift' : 'default'
    });
  };

  closeKeyboard() {
    this.modalCtrl.dismiss();
  }

}
