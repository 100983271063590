import {Injectable} from '@angular/core';
import {ApiResponse, BaseApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {UserSessionService} from '../Models/user-session.service';
import {OrdersModelService} from '../Models/orders-model.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersApiService extends BaseApiService {

  constructor(
    public http: HttpClient,
    public userSession: UserSessionService,
    public model: OrdersModelService
  ) {
    super(http, userSession);
  }

  public getOrdersApi(params: any = null, save: boolean = true): Promise<ApiResponse> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('orders', params).subscribe((response: any) => {

        if (save) {
          this.data = this.model.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public deleteProductOrderApi(params: any = null): Promise<ApiResponse> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('orders/deletePedidoLinea', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));

    });
  }

  public nextStatusOrderApi(params: any = null): Promise<ApiResponse> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('orders/setPedidoNextState', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));

    });
  }

  public previousStatusOrderApi(params: any = null): Promise<ApiResponse> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('orders/setPedidoPreviousState', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));

    });
  }

  public setQuantityProduct(params: any = null): Promise<ApiResponse> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('orders/setCantidadPedidoLinea', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));

    });
  }

  public addProductOrder(params: any = null): Promise<ApiResponse> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('orders/setPedidoLinea', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));

    });
  }

  public setCheckProduct(params: any = null): Promise<ApiResponse> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('orders/checkPreparacionPedidoLinea', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));

    });
  }

  public setTraceabilityProduct(params: {id_pedido: number; codigo: string}): Promise<ApiResponse> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('orders/setPedidoLineaTrazabilidadPorCodigo', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));

    });
  }


}
