import {Component, Injector, OnInit} from '@angular/core';
import {SafeResourceUrl} from '@angular/platform-browser';
import {UserSessionService} from '../../../Services/Models/user-session.service';
import {PageController} from '../../../Pages/Core/page-controller/page-controller';

@Component({
  selector: 'yu-menu-apps',
  templateUrl: './menu-apps.component.html',
  styleUrls: ['./menu-apps.component.scss'],
})
export class MenuAppsComponent extends PageController implements OnInit {

  user: any;

  valueApp: number;
  title: string;
  url: SafeResourceUrl;

  apps: any[] = [];

  constructor(
    protected injector: Injector,
    protected userSession: UserSessionService
  ) {
    super(injector);

    this.user = this.userSession.get();
    this.initIonicEvents();
  }

  ngOnInit() {
    this.initApps();
  }


  /**
   * Inicializar eventos ionic
   *
   */
  initIonicEvents() {
    this.eventCtrl.observe('menu:refresh-permissions').subscribe(() => {
      this.authApi.refreshPermissions().then(() => {
        this.user = this.userSession.get();
        this.initApps();
      });
    });
  }


  /**
   * Inicializar apps
   *
   */
  initApps() {
    this.apps = [
      {
        id: 1,
        name: 'App Clientes',
        url: 'https://movil.yurest.com',
        hasPermission: this.permissionsManager.checkPermission('clientes_app', 'ver')
      },
      {
        id: 2,
        name: 'App Proveedor',
        url: 'https://movil.proveedor.yurest.com',
        hasPermission: this.user.has_integracion && this.permissionsManager.checkPermission('proveedores_app', 'ver')
      },
      {
        id: 3,
        name: 'Fichador',
        url: 'https://fichador.yurest.com',
        hasPermission: this.permissionsManager.checkPermission('fichador_app', 'ver')
      },
      {
        id: 4,
        name: 'Amparo',
        url: 'https://seaprhos.yurest.com',
        hasPermission: this.permissionsManager.checkPermission('amparo_app', 'ver')
      },
    ];

    this.apps = this.apps.filter((app) => app.hasPermission);
    const appSelected = this.apps.find((item) => item.id === this.valueApp);

    if (this.apps.length > 0 && !appSelected) {
      this.valueApp = 0;
      this.viewApp();
    }
  }


  /**
   * Mostrar app en el visor
   *
   */
  viewApp() {

    if (!this.valueApp) {
      this.valueApp = this.apps[0].id;
    }

    const app = this.apps.find((item) => item.id === this.valueApp);

    this.title = app.name;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(app.url);
  }

}
