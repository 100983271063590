import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'turns'
})
export class TurnsPipe implements PipeTransform {

  key: any;

  transform(items: any[], idEstablishment: number = null): any[] {

    if (items) {

      if (idEstablishment) {

        this.key = 'id_locales';

        items = items.filter(item => {
          return item[this.key] === idEstablishment;
        });

      }

      return items;
    }

  }

}
