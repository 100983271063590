import {Pipe, PipeTransform} from '@angular/core';
import {OrdersDto} from '../../Services/Models/orders-model.service';
import {ProductsOrderDto} from '../../Services/Models/products-orders-model.service';

@Pipe({
  name: 'orderList'
})
export class OrderListPipe implements PipeTransform {

  transform(items: OrdersDto[], product: ProductsOrderDto) {

    if (items) {

      if (product) {
        items = items.filter((item) => product.id_pedidos.includes(item.id));
      }

      return items;
    }

  }

}
