import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class WastageTypesModelService extends ModelService {

  tableName = 'kds_wastage_types';
}

export interface WastageTypesDto extends BaseDto {
  id: number;
  id_cliente: number;
  nombre: string;
  descripcion: string;
}
