import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class LoaderManagerService {

  private loader: HTMLIonLoadingElement | null;

  constructor(
    private loadingCtrl: LoadingController
  ) {
  }

  public async show(translucent: boolean = true) {

    if (this.loader) {
      return;
    }

    this.loader = await this.loadingCtrl.create({translucent});
    await this.loader.present();
  }

  public hide(delay: number = 0) {

    if (!this.loader) {
      return;
    }

    setTimeout(() => {
      this.loader.dismiss();
      this.loader = null;
    }, delay);

  }

}
