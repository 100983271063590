import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {

  timeUpInterval: any;
  totalTime: number = 0;

  constructor() {
  }

  ngOnInit() {
  }

  /**
   * Resetear tiempo
   *
   */
  public reset(startTime: number = 0) {
    this.totalTime = startTime;
  }


  /**
   * Empezar tiempo
   *
   */
  public start(startTime: number = 0) {
    this.reset(startTime);

    if (!this.timeUpInterval) {
      this.timeUpInterval = setInterval(() => {
        this.totalTime++;
      }, 1000);
    }
  }


  /**
   * Parar tiempo
   *
   */
  public stop() {
    if (this.timeUpInterval) {
      clearInterval(this.timeUpInterval);
      this.timeUpInterval = null;
    }
  }
}
