import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class FamiliesModelService extends ModelService {

  tableName = 'kds_families';
}

export interface FamiliesDto extends BaseDto {
  id: number;

  id_familia?: number;
  nombre: string;
}
