import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';
import {UnitDto} from './products-model.service';

@Injectable({
  providedIn: 'root'
})
export class LastProductionsModelService extends ModelService {

  tableName = 'kds_last_productions';
}

export interface LastProductionsDto extends BaseDto {
  avatar: string;
  caducado: number;
  cantidad_final: number;
  cantidad_inicio: number;
  descripcion: string;
  dias_utiles: number;
  fecha_cad: string;
  fecha_cad_u: string;
  fecha_fin: string;
  fecha_fin_u: string;
  fecha_inicio: string;
  fecha_inicio_u: string;
  id: number;
  id_elaboracion: number;
  id_familia: null;
  id_local: number;
  id_receta: null;
  id_tarea: null;
  id_ubicacion: number;
  id_usuario: number;
  imagen: string;
  local: string;
  merma: number;
  nombre: string;
  precio: number;
  producto_final: Array<ProductDto>;
  producto_inicio: Array<ProductDto>;
  referencia: string;
  tiempo_activo: string;
  tiempo_produccion: string;
  ubicacion: string;
  unidad_final: string;
  unidad_inicio: string;
  usuario: string;
}

export interface ProductDto extends BaseDto {
  activo: number;
  compra: number;
  coste: number;
  coste_unidad_master: number;
  descripcion: string;
  foto: string;
  id: number;
  id_cliente: number;
  id_familia: number;
  id_familia_ventas: number;
  id_generico: number;
  id_iva: number;
  id_producto_externo: number;
  id_tpv: number;
  id_unidad: number;
  id_vajilla: number;
  id_zona_saona: number;
  imagen_url: string;
  nombre: string;
  precio_mejor: number;
  precio_referencia: number;
  precio_venta: number;
  unidad: Array<UnitDto>;
  venta: number;
  video: string;
  video_youtube: string;
}
