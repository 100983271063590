import { Injectable } from '@angular/core';
import {ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionProductsService  extends ModelService {

  tableName = 'kds_productions_products';
}
