import {Component} from '@angular/core';
import {MenuController, Platform} from '@ionic/angular';
import {IonicEventsService} from './Services/Components/ionic-events.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  showMenu: boolean = false;

  constructor(
    private platform: Platform,
    private eventCtrl: IonicEventsService,
    private menuCtrl: MenuController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.eventOpenMenu();
    });
  }

  eventOpenMenu() {
    this.eventCtrl.observe('menu:show')?.subscribe(() => {
      this.showMenu = !this.showMenu;

      if (this.showMenu) {
        this.menuCtrl.enable(true);
        this.eventCtrl.publish({name: 'menu:refresh-permissions'});
      }
    });
  }

}
