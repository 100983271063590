import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './Providers/Routes/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'onboarding',
    pathMatch: 'full'
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./Pages/Auth/onboarding/onboarding.module').then(m => m.OnboardingPageModule)
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./Pages/Core/tabs/tabs.module').then(m => m.TabsPageModule)
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./Pages/Auth/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./Pages/Auth/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'visor',
    loadChildren: () => import('./Pages/Auth/visor/visor.module').then(m => m.VisorPageModule)
  },
  {
    path: 'gallery-photos',
    loadChildren: () => import('./Pages/Multimedia/gallery-photos/gallery-photos.module').then(m => m.GalleryPhotosPageModule)
  },
  {
    path: 'details-ingredient',
    loadChildren: () => import('./Pages/Ingredients/details-ingredient/details-ingredient.module').then(m => m.DetailsIngredientPageModule)
  },
  {
    path: 'video',
    loadChildren: () => import('./Pages/Multimedia/video/video.module').then(m => m.VideoPageModule)
  },
  {
    path: 'recipe-or-elaboration',
    loadChildren: () =>
      import('./Pages/Options/recipe-or-elaboration/recipe-or-elaboration.module').then(m => m.RecipeOrElaborationPageModule)
  },
  {
    path: 'add-ingredient',
    loadChildren: () => import('./Pages/Ingredients/add-ingredient/add-ingredient.module').then(m => m.AddIngredientPageModule)
  },
  {
    path: 'print-tags',
    loadChildren: () => import('./Pages/Tags/print-tags/print-tags.module').then(m => m.PrintTagsPageModule)
  },
  {
    path: 'traceability',
    loadChildren: () => import('./Pages/Ingredients/traceability/traceability.module').then(m => m.TraceabilityPageModule)
  },
  {
    path: 'recipes-list',
    loadChildren: () => import('./Pages/Recipes/recipes-list/recipes-list.module').then(m => m.RecipesListPageModule)
  },
  {
    path: 'productions-list',
    loadChildren: () => import('./Pages/Productions/productions-list/productions-list.module').then(m => m.ProductionsListPageModule)
  },
  {
    path: 'notifications-list',
    loadChildren: () =>
      import('./Pages/Notifications/notifications-list/notifications-list.module').then(m => m.NotificationsListPageModule)
  },
  {
    path: 'notifications-time',
    loadChildren: () =>
      import('./Pages/Notifications/notifications-time/notifications-time.module').then(m => m.NotificationsTimePageModule)
  },
  {
    path: 'formats-product',
    loadChildren: () => import('./Pages/Formats/formats-product/formats-product.module').then(m => m.FormatsProductPageModule)
  },
  {
    path: 'set-devices',
    loadChildren: () => import('./Pages/Configuration/set-devices/set-devices.module').then(m => m.SetDevicesPageModule)
  },
  {
    path: 'users-list',
    loadChildren: () => import('./Pages/Users/users-list/users-list.module').then(m => m.UsersListPageModule)
  },
  {
    path: 'keyboard',
    loadChildren: () => import('./Pages/Controls/keyboard/keyboard.module').then(m => m.KeyboardPageModule)
  },
  {
    path: 'set-multisession',
    loadChildren: () => import('./Pages/Configuration/set-multisession/set-multisession.module').then(m => m.SetMultisessionPageModule)
  },
  {
    path: 'add-more-info-tag',
    loadChildren: () => import('./Pages/Tags/add-more-info-tag/add-more-info-tag.module').then(m => m.AddMoreInfoTagPageModule)
  },
  {
    path: 'set-product-order',
    loadChildren: () => import('./Pages/Orders/set-product-order/set-product-order.module').then(m => m.SetProductOrderPageModule)
  },
  {
    path: 'add-continued-info',
    loadChildren: () => import('./Pages/Tags/add-continued-info/add-continued-info.module').then(m => m.AddContinuedInfoPageModule)
  },
  {
    path: 'search-tag',
    loadChildren: () => import('./Pages/Tags/search-tag/search-tag.module').then(m => m.SearchTagPageModule)
  },
  {
    path: 'keyboard-virtual',
    loadChildren: () => import('./Pages/Controls/keyboard-virtual/keyboard-virtual.module').then(m => m.KeyboardVirtualPageModule)
  },
  {
    path: 'set-inventory-quantity',
    loadChildren: () => import('./Pages/Inventory/set-inventory-quantity/set-inventory-quantity.module').then(m => m.SetInventoryQuantityPageModule)
  },
  {
    path: 'add-wastage',
    loadChildren: () => import('./Pages/Wastage/add-wastage/add-wastage.module').then(m => m.AddWastagePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
