import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class ToastManagerService {


  constructor(public toastController: ToastController) {

  }

  public showToastByStatusCode(data, message, duration = 2000) {

    if (data) {

      this.showSuccessToast(message, duration);

    } else {

      this.showWarningToast(message, duration);
    }
  }

  public showWarningToast(message: string, duration: number = 2500, header?: string) {
    this.showToast(header, message, duration, 'warning');
  }

  public showSuccessToast(message: string, duration: number = 2500, header?: string) {
    this.showToast(header, message, duration, 'success');
  }

  public showErrorToast(message: string, duration: number = 2500, header?: string) {
    this.showToast(header, message, duration, 'danger');
  }

  async showToast(header, mensaje, duracion = 2500, colorToast) {
    const toast = await this.toastController.create({
      header: header,
      message: mensaje,
      duration: duracion,
      position: 'bottom',
      color: colorToast,
      buttons: [
        {
          text: '',
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });

    if (duracion > 0) {

      toast.present();
    }
  }
}
