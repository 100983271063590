import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsOrdersModelService extends ModelService {

  tableName = 'kds_products_orders';
}

export interface ProductsDataApiDto extends BaseDto {
  productos: ProductsOrderDto[];
  ubicaciones: string[];
}

export interface ProductsOrderDto extends BaseDto {
  id: number;
  id_producto: null;
  id_familia: number;
  nombre: string;
  cliente_nombre: string;
  prov_nombre: string;
  id_articulo: number;
  foto: string;
  id_pedidos: number[];
  num_pedidos: number;
  cantidad: number;
  cantidad_calc: number;
  unidad: string;
  ubicacion: string;
  formato: number;
  formato_compra: string;
  formato_minimo: number;
  formato_multiplo: number;
  formato_multiplo_label: string;
  formato_ver: string;
  newest_items: string;
  oldest_items: string;
}
