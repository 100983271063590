import { Injectable } from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class TurnsModelService extends ModelService {

  tableName = 'kds_turns';
}

export interface TurnsDto extends BaseDto {
  id: number;

  id_cliente?: number;
  nombre: string;
}
