import {Injector} from '@angular/core';
import {NavParams} from '@ionic/angular';
import {PageController} from '../page-controller/page-controller';

export abstract class ModalPageController extends PageController {

  protected navParams: NavParams;
  public keyBoardVisible: boolean = false;

  protected constructor(
    protected injector: Injector
  ) {
    super(injector);

    this.navParams = this.injector.get<NavParams>(NavParams);
  }

  dismiss(result = null, role = '') {

    if (this.keyBoardVisible) {

      setTimeout(() => this.dismissModal(result, role), 150);

    } else {

      this.dismissModal(result, role);
    }
  }


  async dismissModal(result = null, role = '') {
    await this.modalCtrl.dismiss(result, role);
  }
}
