import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectAllDirective} from './select/select-all.directive';
import {NumberDirective} from './number/number.directive';
import {KeyboardDirective} from './keyboard/keyboard.directive';

const declarationsExports = [
  SelectAllDirective,
  NumberDirective,
  KeyboardDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [...declarationsExports],
  exports: [...declarationsExports],
})
export class DirectivesModule {
}
