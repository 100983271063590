import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentsModelService extends ModelService {

  tableName = 'kds_establishments';
}

export interface EstablishmentsDto extends BaseDto {
  id: number;
  activo: boolean;
  hash_id: string;
  id_and_name: string;
  id_familia: number;
  logo_local: string;
  logo_cliente: string;
  nombre: string;
  email: string;
  direccion: string;
  municipio: string;
  provincia: string;
  cp: string;
  tipo_etiqueta: LabelType;
}

export interface LabelType {
  id: number;
  nombre: string;
}
