import {Injectable} from '@angular/core';
import {UserSessionService} from '../Models/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsManagerService {

  public user: any;

  constructor(
    private userSession: UserSessionService,
  ) {
  }

  public checkPermission(module: string, permission: string): boolean {

    this.user = this.userSession.get();

    if (this.user?.permisos?.application && module && permission) {
      return this.user?.permisos?.application?.[module]?.[permission] ?? false;
    }

    return false;
  }

}
