import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersModelService extends ModelService {

  tableName = 'kds_orders';
}

export interface OrdersDto extends BaseDto {
  id: number;
  id_local: number;
  id_proveedor: number;
  id_usuario: number;
  lineas: LineOrderDto[];
  local: string;
  proveedor: string;
  referencia: string;
  subtotal: NumberDto;
  total: NumberDto;
  usuario: string;
  color_estado: string;
  estado: string;
  id_estado: number;
  fecha: DateDto;
  qr_reader_activated: boolean;
}

export interface LineOrderDto extends BaseDto {
  id: number;
  id_linea: number;
  foto: string;
  unidad: string;
  id_producto: number;
  nombre: string;
  cliente_nombre: string;
  prov_nombre: string;
  id_articulo: number;
  cantidad: number;
  id_pedido?: number;
  cantidad_original: number;
  check_preparacion: boolean;
  updated: string;
  usuario: string;
  id_usuario: number;
  formato: number;
  formato_compra: string;
  formato_minimo: number;
  formato_multiplo: number;
  formato_multiplo_label: string;
  formato_ver: string;
  cods_trazabilidad: TraceabilityDto[];
  total: NumberDto;
  subtotal: NumberDto;
}

export interface TraceabilityDto extends BaseDto {
  cantidad: number;
  codigo: string;
  id: number;
  id_pedido_linea?: number;
}

export interface NumberDto extends BaseDto {
  ver: string;
  ver_mas: string;
  ordenar: number;
  base: number;
  original: number;
}

export interface DateDto extends BaseDto {
  ver: string;
  ordenar: string;
  original: number;
}
