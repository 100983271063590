import {Injectable} from '@angular/core';
import {BaseApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {UserSessionService} from '../Models/user-session.service';
import {LocationsModelService} from '../Models/locations-model.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsApiService extends BaseApiService {

  constructor(
    public http: HttpClient,
    public userSession: UserSessionService,
    public model: LocationsModelService
  ) {
    super(http, userSession);
  }

  public getLocationsApi(id: number, params = null, save: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('locations/' + id, params, 'get').subscribe((response: any) => {

        if (save) {
          this.data = this.model.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public getAllLocationsApi(params = null, save: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpBaseCall('locations/', params, 'get').subscribe((response: any) => {

        if (save) {
          this.data = this.model.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

}
