import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-search-selector',
  templateUrl: './search-selector.component.html',
  styleUrls: ['./search-selector.component.scss'],
})
export class SearchSelectorComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() itemsVelueField: string = 'id';
  @Input() itemsLabelField: string = 'nombre';
  @Input() itemsImageField: string = 'imagen_url';
  @Input() placeholder: string;

  @Input() label: string;
  @Input() seachText: string = 'Buscar';
  @Input() cancelText: string = 'Cancelar';
  @Input() closeText: string = 'Cerrar';
  @Input() doneText: string = 'Guardar';

  @Input() isObligatory: boolean = false;
  @Input() disabled: boolean = false;

  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();


  private selectorModal = null;
  selectedTextValue: string;

  itemsToList: any[] = [];

  constructor(
    private modalCtrl: ModalController
  ) {

  }

  ngOnInit() {
  }


  ngOnChanges(changes: SimpleChanges) {

    if (changes['items'] || changes['value']) {
      this.setValues();
    }
  }


  public setValues() {
    if (this.items) {
      this.itemsToList = this.items;
    }

    this.setValuesItem();
  }

  setValuesItem() {
    if (this.value && this.itemsToList) {

      this.selectedTextValue = '';

      for (let item of this.itemsToList) {
        if (item[this.itemsVelueField] == this.value.id) {
          this.selectedTextValue = item[this.itemsLabelField];
          break;
        }
      }
    }
  }

  async openSelectorModal() {

    if (this.disabled) {
      return false;
    }

    this.selectorModal = await this.modalCtrl.create({
      mode: 'ios',
      component: SearchSelectorModal,
      componentProps: {component: this},
      backdropDismiss: false,
      swipeToClose: true,
      presentingElement: await this.modalCtrl.getTop(),
      cssClass: 'force-medium'
    });

    this.selectorModal.present();
  }
}


@Component({
  templateUrl: './search-selector-modal.html',
  styleUrls: ['./search-selector.component.scss'],
})
export class SearchSelectorModal implements OnInit {

  @Input() component: SearchSelectorComponent;

  searchValue: string;

  constructor(private modalCtrl: ModalController) {

  }

  ngOnInit() {
    this.component.setValues();
  }

  selectItem(itemSelected) {
    this.component.selectedTextValue = itemSelected[this.component.itemsLabelField];

    this.component.value = itemSelected;
    this.component.valueChange.emit(this.component.value);

    this.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
