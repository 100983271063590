import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ingredients-recipe',
  templateUrl: './ingredients-recipe.component.html',
  styleUrls: ['./ingredients-recipe.component.scss'],
})
export class IngredientsRecipeComponent implements OnInit {

  @Input() ingredient: any;

  constructor() {
  }

  ngOnInit() {
  }

}
