import {IonDatetime, ModalController} from '@ionic/angular';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {format, parse, parseISO} from 'date-fns';
import {es} from 'date-fns/locale';
import {PresentationTypeEnum} from '../../../Utils/Enum/presentation-type.enum';
import {AnimationsManagerService} from '../../../Services/Components/animations-manager.service';

@Component({
  selector: 'yu-datetime',
  templateUrl: './yu-datetime.component.html',
  styleUrls: ['./yu-datetime.component.scss'],
})
export class YuDatetimeComponent {

  public internalValue: string;
  public formattedDate: string;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() set value(newValue: string) {
    this.internalValue = newValue;
    this.valueChange.emit(this.internalValue);

    if (newValue) {
      this.formattedDate = this.toString(
        this.toDate(this.value, this.inputOutputFormat),
        this.displayFormat
      );
    }
  }

  get value(): string {
    return this.internalValue;
  }

  @Input() texto: string;
  @Input() displayFormat: string = 'dd/MM/yyyy';
  @Input() inputOutputFormat: string = 'yyyy-MM-dd';
  @Input() esObligatorio: boolean = false;
  @Input() presentation: PresentationTypeEnum = PresentationTypeEnum.Date;
  @Input() cancelText: string = 'Cancelar';
  @Input() doneText: string = 'Ok';
  @Input() min: string;
  @Input() max: string;
  @Input() lines: string = undefined;
  @Input() disabled: boolean = false;
  @Input() minuteValues: string = undefined;
  @Input() canDelete: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    protected animationsService: AnimationsManagerService
  ) {
  }

  removeDate() {
    this.value = '';
    this.formattedDate = '';
    this.internalValue = '';
  }

  public async openModal() {
    const modal = await this.modalCtrl.create({
      enterAnimation: this.animationsService.fadeInAnimation,
      leaveAnimation: this.animationsService.fadeOutAnimation,
      component: YuDatetimeModal,
      componentProps: {
        dateValue: this.value
          ? this.toString(
            this.toDate(this.value, this.inputOutputFormat),
            this.inputOutputFormat
          )
          : this.toString(new Date(), this.inputOutputFormat),
        presentation: this.presentation,
        cancelText: this.cancelText,
        doneText: this.doneText,
        min: this.min,
        max: this.max,
        inputOutputFormat: this.inputOutputFormat,
        minuteValues: this.minuteValues,
      },
      cssClass: 'transparent-modal force-fullscreen',
    });

    modal.onDidDismiss().then((data) => {
      if (data?.data) {
        this.value = this.toString(
          this.toDate(data.data, this.inputOutputFormat),
          this.inputOutputFormat
        );
      }
    });

    await modal.present();
  }

  private toDate(date: string, formatValue: string = 'yyyy-MM-dd'): Date {
    return parse(date, formatValue, new Date());
  }

  private toString(date: Date, formatValue: string = 'yyyy-MM-dd'): string {
    return format(date, formatValue, {locale: es});
  }
}

@Component({
  templateUrl: './yu-datetime-modal.html',
})
export class YuDatetimeModal {

  @Input() presentation: PresentationTypeEnum = PresentationTypeEnum.Date;
  @Input() dateValue: string;
  @Input() cancelText: string;
  @Input() doneText: string;
  @Input() min: string;
  @Input() max: string;
  @Input() inputOutputFormat: string = 'yyyy-MM-dd';
  @Input() minuteValues: string = undefined;

  constructor(private modalCtrl: ModalController) {
  }

  public changeDate(value: any) {
    if (this.presentation === PresentationTypeEnum.Time) {
      this.dateValue = value;
    } else {
      this.dateValue = this.toString(parseISO(value));
    }
  }

  public dismiss(value?: string) {
    this.modalCtrl.dismiss(value);
  }

  public async cancel(datetime: IonDatetime) {
    await datetime.cancel();
    this.dismiss();
  }

  public async done(datetime: IonDatetime) {
    await datetime.confirm();
    this.dismiss(this.dateValue);
  }

  private toString(date: Date): string {
    return format(date, this.inputOutputFormat);
  }
}
