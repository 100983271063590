import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsModelService extends ModelService {

  tableName = 'kds_locations';
}

export interface LocationsDto extends BaseDto {
  id: number;

  activo: number;

  diff_cantidad: number;
  dont_list: number;

  id_almacen_tipo: number;
  id_almacen_ubicacion?: number;
  id_local: number;
  nombre: string;
}
