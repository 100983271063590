export class CustomNumber {

  public static splitComaToPunto(value: string): string {
    return value.replace(/(,+)/g, '.');
  }

  public static formatNumber(value: string): number {
    return parseFloat(value);
  }
}
