import {Component, Injector, OnInit} from '@angular/core';
import {PageController} from '../../Core/page-controller/page-controller';
import {ApiResponse} from '../../../Services/Api/api.service';
import {DevicesApiService} from '../../../Services/Api/devices-api.service';
import {DevicesDto} from '../../../Services/Models/devices-model.service';
import {IonItemSliding} from '@ionic/angular';
import {FiltersDto, FiltersModelsService} from '../../../Services/Models/filters-models.service';
import {FixDevicesModelService} from '../../../Services/Models/fix-devices-model.service';

@Component({
  selector: 'app-set-devices',
  templateUrl: './set-devices.page.html',
  styleUrls: ['./set-devices.page.scss'],
})
export class SetDevicesPage extends PageController implements OnInit {

  devices: DevicesDto[];

  filters: FiltersDto;

  fixDevices: any;

  constructor(
    protected injector: Injector,
    protected devicesService: DevicesApiService,
    protected filtersModel: FiltersModelsService,
    protected fixDevicesModel: FixDevicesModelService,
  ) {
    super(injector);
  }

  async ngOnInit() {
    await this.getFilters();
    await this.getFixesDevices();

    this.getDevices();
  }


  /**
   * Obtener los dispositivos fijados
   *
   */
  async getFixesDevices() {
    this.fixDevices = await this.fixDevicesModel.list();

    if (!this.fixDevices) {
      this.fixDevices = {
        printer: null,
        scale: null
      };

      this.fixDevicesModel.save(this.fixDevices);
    }
  }


  /**
   * Obtener filtros
   *
   */
  async getFilters() {
    this.filters = await this.filtersModel.list();
  }


  /**
   * Obtener dispositivos
   *
   */
  getDevices() {
    this.devicesService.getDevicesApi().then((response: ApiResponse) => {
      this.devices = response.data;

      // Marcar los dispositivos que están fijados
      for (let device of this.devices) {
        if (this.fixDevices.printer && device.id == this.fixDevices.printer.id ||
          this.fixDevices.scale && device.id == this.fixDevices.scale.id) {
          device.fijado = true;
        }
      }
    });
  }


  /**
   * Fijar dispositivo
   *
   */
  fixDevice(device, ionItemSliding: IonItemSliding) {

    if (ionItemSliding) {
      ionItemSliding.close();
    }

    switch (device.id_tipo) {

      // Báscula
      case 1:

        // Desfijar
        if (device.fijado) {
          this.fixDevices.scale = null;

          device.fijado = false;

          // Fijar
        } else {
          this.fixDevices.scale = {
            id: device.id,
            ip: device.ip,
            nombre: device.nombre,
            local: device.local
          };

          let scales = this.devices.filter((item) => item.id_tipo === 1);

          // Marcar y desmarcar los dispositivos
          for (let scale of scales) {
            if (scale.id === device.id) {
              scale.fijado = true;

            } else {
              scale.fijado = false;
            }
          }
        }

        break;

      // Impresora
      case 2:

        // Desfijar
        if (device.fijado) {
          this.fixDevices.printer = null;

          device.fijado = false;

          // Fijar
        } else {
          this.fixDevices.printer = {
            id: device.id,
            ip: device.ip,
            nombre: device.nombre,
            local: device.local
          };

          let printers = this.devices.filter((item) => item.id_tipo === 2);

          // Marcar y desmarcar los dispositivos
          for (let printer of printers) {
            if (printer.id === device.id) {
              printer.fijado = true;

            } else {
              printer.fijado = false;
            }
          }

        }

        break;
    }

    // Guardar los dispositivos fijados
    this.fixDevicesModel.save(this.fixDevices);
  }

}
