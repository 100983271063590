import {Injectable} from '@angular/core';
import {ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsModelService extends ModelService {

  tableName = 'kds_notifications';
}
