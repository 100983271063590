import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {TextToSpeech} from '@capacitor-community/text-to-speech';

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {

  forcePlugin: boolean = true;
  isSpeakingNow: boolean = false;
  utterance: any;

  constructor(
    private platform: Platform
  ) {
    const platfotm = this.platform.is('ios') ? 'ios' : 'android';

    if (platfotm === 'ios') {
      this.utterance = new SpeechSynthesisUtterance();
      this.forcePlugin = false;
    }
  }

  async speak(message) {

    if (this.utterance && !this.forcePlugin) {
      this.utterance.text = message;
      this.utterance.pitch = 0.95;
      this.utterance.rate = 0.91;

      speechSynthesis.speak(this.utterance);

    } else {

      this.isSpeakingNow = true;

      await TextToSpeech.speak({
        text: message,
        lang: 'es-ES',
        rate: 1,
        pitch: 1,
        volume: 1.0,
        //voice: 114,
        category: 'ambient'
      }).finally(() => {
        this.isSpeakingNow = false;
      });
    }
  }

  isSpeaking() {
    return this.utterance && !this.forcePlugin ? speechSynthesis.speaking : this.isSpeakingNow;
  }

  cancel() {
    if (this.utterance && !this.forcePlugin) {

      speechSynthesis.cancel();

    } else {
      this.pause();
    }
  }

  pause() {
    if (this.utterance && !this.forcePlugin) {

      speechSynthesis.pause();

    } else {
      TextToSpeech.stop().finally(() => {
        this.isSpeakingNow = false;
      });
    }
  }

}
