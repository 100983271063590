import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {UserSessionService} from '../../Services/Models/user-session.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {NavController} from '@ionic/angular';
import {ToastManagerService} from '../../Services/Components/toastManager.service';
import {DataManagerService} from '../../Services/Components/data-manager.service';

@Injectable()
export class InterceptorProvider implements HttpInterceptor {

  user: any;

  constructor(
    private navCtl: NavController,
    private dataManager: DataManagerService,
    private userService: UserSessionService,
    private toastCtrl: ToastManagerService
  ) {
    this.user = this.userService.get();
  }

  /**
   * Añade el token a la petición
   *
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(request)).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {

          if (evt.body?.status === -1) {

            this.userService.delete();
            this.dataManager.deleteData();

            this.navCtl.navigateRoot([''], {animated: false, animationDirection: 'forward'});
            this.toastCtrl.showWarningToast(evt.body.mensaje);
          }
        }
      }));
  }


  /**
   * Añade el token en los Headers si hay sesion de usuario y existe el token
   *
   */
  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    this.user = this.userService.get();

    if (this.user && 'id' in this.user && 'token' in this.user) {
      let clone: HttpRequest<any>;

      clone = request.clone({
        setHeaders: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.user.token + ''
        }
      });

      return clone;
    }

    return request;
  }

}
