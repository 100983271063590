import { Injectable } from '@angular/core';
import {BaseApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {UserSessionService} from '../Models/user-session.service';
import {DepartmentsModelService} from '../Models/departments-model.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsApiService extends BaseApiService {

  constructor(
    public http: HttpClient,
    public userSession: UserSessionService,
    public model: DepartmentsModelService
  ) {
    super(http, userSession);
  }

  public getDepartmentsApi(params: any = null, save: boolean = true): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('departments', params, 'get').subscribe((response: any) => {

        if (save) {
          this.data = this.model.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }
}
