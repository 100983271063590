import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'miseEnPlace'
})
export class MiseEnPlacePipe implements PipeTransform {

  transform(items: any[], familia: number, partidas: number[], menu: string): any[] {

    if (items) {

      if (familia) {
        items = items.filter((item) => item.id_familia === familia);
      }

      if (partidas.length > 0) {
        items = items.filter((item) => partidas.includes(item.id_partida));
      }

      if (menu === 'stock') {
        items = items.filter((item) => item.stock_critico);
      }

      return items;
    }

  }

}
