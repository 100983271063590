import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'locations'
})
export class LocationsPipe implements PipeTransform {

  key: any;

  transform(items: any[], idEstablishment: number = null): any[] {

    if (items) {

      if (idEstablishment) {

        this.key = 'id_local';

        items = items.filter(item => {
          return item[this.key] === idEstablishment;
        });

      }

      return items;
    }

  }

}
