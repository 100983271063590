import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IntegrationsScalesService} from '../../../Services/Integrations/integrations-scales.service';
import {DevicesDto} from '../../../Services/Models/devices-model.service';

@Component({
  selector: 'app-scale-buttons',
  templateUrl: './scale-buttons.component.html',
  styleUrls: ['./scale-buttons.component.scss'],
})
export class ScaleButtonsComponent {

  @Input() scales: DevicesDto[];
  @Input() unit: string;

  @Input() smallButtons = false;

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  constructor(
    public scaleService: IntegrationsScalesService,
  ) {
  }

  /**
   * Obtener el peso de la báscula
   *
   */
  async getWeightScale(isTara = false) {
    this.scaleService.getWeightFromScale(this.scales, this.unit, isTara).then((data) => {
      if (!isTara) {
        this.value = data;
        this.valueChange.emit(this.value);
      }
    });
  }

}
