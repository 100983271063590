import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {NavController} from '@ionic/angular';
import {AuthService} from '../../Services/Api/auth.service';
import {DataManagerService} from '../../Services/Components/data-manager.service';
import {ToastManagerService} from '../../Services/Components/toastManager.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private navCtl: NavController,
    private auth: AuthService,
    private toastCtrl: ToastManagerService,
    private dataManager: DataManagerService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.userIsLogIn()) {
      return true;

    } else {
      this.dataManager.deleteData();

      this.navCtl.navigateRoot([''], {animated: false, animationDirection: 'forward'});
      this.toastCtrl.showWarningToast('La sesión se ha caducado vuelve a iniciar la sesión.');
      return false;
    }
  }
}
