import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ingredients-production',
  templateUrl: './ingredients-production.component.html',
  styleUrls: ['./ingredients-production.component.scss'],
})
export class IngredientsProductionComponent implements OnInit {

  @Input() ingredient: any;
  @Input() menu: string;

  constructor() {
  }

  ngOnInit() {
  }

}
