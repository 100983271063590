import {Injectable} from '@angular/core';
import {BaseDto, ModelService} from './model.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsModelService extends ModelService {

  tableName = 'kds_departments';
}

export interface DepartmentDto extends BaseDto {
  id: number;
  descripcion: string;
  ids_locales: number[];
  nombre: string;
}
