import { Injectable } from '@angular/core';
import {BaseApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {UserSessionService} from '../Models/user-session.service';
import {ProductsModelService} from '../Models/products-model.service';
import {ProductsSupplierModelService} from '../Models/products-supplier-model.service';
import {ProductsOrdersModelService} from '../Models/products-orders-model.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsApiService extends BaseApiService {

  constructor(
    public http: HttpClient,
    public userSession: UserSessionService,
    public model: ProductsModelService,
    public modelProductsOrder: ProductsOrdersModelService,
    public modelProductsSupplier: ProductsSupplierModelService
  ) {
    super(http, userSession);
  }

  public getProductsApi(params: any = null, save: boolean = true): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('ingredients', params, 'get').subscribe((response: any) => {

        if (save) {
          this.data = this.model.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public getProductsOrdersApi(params: any = null, save: boolean = true): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('orders/products', params).subscribe((response: any) => {

        if (save) {
          this.data = this.modelProductsOrder.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));

    });
  }

  public getProductsSupplierApi(params: any = null, save: boolean = true): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('products/catalogo', params).subscribe((response: any) => {

        if (save) {
          this.data = this.modelProductsSupplier.save(response.data);
        }

        resolve(response);
      }, (err: any) => reject(err));
    });
  }

  public reorderProducts(params: any = null): Promise<any> {

    return new Promise((resolve, reject) => {

      return this.httpBaseCall('', params).subscribe((response: any) => {
        resolve(response);
      }, (err: any) => reject(err));

    });
  }

}
