import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-product-final-production',
  templateUrl: './product-final-production.component.html',
  styleUrls: ['./product-final-production.component.scss'],
})
export class ProductFinalProductionComponent implements OnInit {

  @Input() elaborationProduction: any;

  constructor() {
  }

  ngOnInit() {
  }

}
