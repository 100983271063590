import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-steps-production',
  templateUrl: './steps-production.component.html',
  styleUrls: ['./steps-production.component.scss'],
})
export class StepsProductionComponent implements OnInit {

  @Input() step: any;
  @Input() i: number;

  @Output() onClickSetStep = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  setStepProduction(e) {
    this.onClickSetStep.emit(this.step);
  }
}
